import {FETCH_LABELS_CONFIG} from "../types";

export function fetchLabelsConfig(game) {
    return async  dispatch => {
        try {
            const response = await fetch(`/${game}/label-configs`, {method: 'GET'});
            const body = await response.text();
            let payload = null;
            if (body !== '') {
                payload = JSON.parse(body);

            }
            dispatch({type: FETCH_LABELS_CONFIG, payload})
        } catch (e) {
            dispatch({type: FETCH_LABELS_CONFIG, payload: {}})
        }
    }
}