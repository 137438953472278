import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel,
    Link, List, ListItem, ListItemButton, ListItemText, TextareaAutosize,
    TextField, Typography
} from "@mui/material";
import {isEmptyArray, isExists} from "../utils";
import {pushEventChangesInAsana, sendRemoveEvent} from "../requests";
import {SELECTED_GAME} from "../constants";
import {hideImportantDatesTimeRange} from "../calendars/bryntum_helpers";

export default function DeletePopup({deleteRecords, eventStore, selectedGame, existsInAsana, onClose, scheduler}) {

    const [isHardDelete, setIsHardDelete] = useState(false);
    const [isPublishedInAsana, setIsPublishedInAsana] = useState(false);

    function handleClickDelete() {
        deleteRecords.forEach((deletedEvent) => {
            if (isExists(deletedEvent.nestedIdEvents) && !isEmptyArray(deletedEvent.nestedIdEvents)) {
                eventStore.remove(deletedEvent.nestedIdEvents);
                eventStore.remove(deletedEvent.id)
            }
            sendRemoveEvent(deletedEvent, selectedGame.toLowerCase(), isHardDelete).then(r => {
                    if (isPublishedInAsana) {
                        return pushEventChangesInAsana(deletedEvent, selectedGame.toLowerCase())
                    }
                }
            );
            hideImportantDatesTimeRange(scheduler, deletedEvent);
        })
        onClose()
    }


    return (
        <div className='popup-mask'>
            <div className='popup-note'>
                <header>
                    Подтвердить удаление
                </header>
                <FormControlLabel sx={{
                    margin: "auto",
                    display: selectedGame === 'CM' || selectedGame === 'RT' ? "flex" : "none"
                }}
                                  control={
                                      <Checkbox
                                          checked={isHardDelete}
                                          onChange={(event) => setIsHardDelete(event.target.checked)}
                                      />
                                  }
                    // label="Удалить события из асаны"
                                  label="В том числе удалить из Асаны?"
                />
                <FormControlLabel sx={{margin: "auto", display : existsInAsana ? "flex" : "none"}}
                                  control={
                                      <Checkbox
                                          checked={isPublishedInAsana}
                                          onChange={(event) => setIsPublishedInAsana(event.target.checked)}
                                      />
                                  }
                    // label="Удалить события из асаны"
                                  label="Синхронизировать с Асаной?"
                />
                <Box sx={{display: "flex", justifyContent: "center"}}>
                    <List>
                        {deleteRecords.map((item, index) => {
                            return <ListItem key={index} component={Link} href={item.link} target="_blank" sx={{pt: 0}}>
                                <ListItemButton>
                                    <ListItemText
                                        primary={item.name}
                                    />
                                </ListItemButton>
                            </ListItem>

                        })}


                    </List>
                </Box>
                <footer>
                    <Button variant="contained" color="primary" onClick={handleClickDelete}>OK</Button>
                    <Button variant="contained" color="secondary" onClick={onClose}>Cancel</Button>
                </footer>
            </div>
        </div>
    )
}