//import AdapterDateFns from "@mui/lab/AdapterDateFns";
//import {DatePicker, LocalizationProvider} from "@mui/lab";
import {TextField} from "@mui/material";
import React from "react";
import ruLocale from 'date-fns/locale/ru';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

export default function CalendarDatePicker({date, dateChangeHandler, label, styles, props}) {
    return <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <DatePicker
            disableToolbar
            variant="standard"
            margin="normal"
            label={label}
            mask="__.__.____"
            renderInput={(params) => <TextField {...params}
                                                variant="standard"
                                                sx={styles}/>}
            value={date}
            onChange={(e) => dateChangeHandler(e, props ? props.game : null)}/>
    </LocalizationProvider>
}