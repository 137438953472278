
import React, { useState } from "react"
import {amber} from "@mui/material/colors";
import {Divider, Fab, Popover, Typography, Box} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: "absolute",
        bottom: 30,
        right: 30,
        background: amber[500],
        "&:hover": {
            background: amber[300]
        }
    },
    typography: {
        margin: 2
    },
    white: {
        color: "fff"
    }
}))

const HelpButton = () => {
    const classes = useStyles()

    const [popoverAnchor, setPopoverAnchor] = useState(null)

    const handleClickHelp = ({ currentTarget }) => {
        setPopoverAnchor(currentTarget)
    }

    const handleCloseHelp = () => {
        setPopoverAnchor(null)
    }

    return (
        <>
            <Fab
                className={classes.fab}
                onClick={handleClickHelp}>
                <Typography
                    sx={{color: "white"}}
                    variant="h4">
                    ?
                </Typography>
            </Fab>
            <Popover
                anchorEl={popoverAnchor}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                open={popoverAnchor !== null}
                onClose={handleCloseHelp}>
                <Typography sx={{m: 2}}>
                    Mouse wheel + Ctrl = Zoom in/out
                    <br />
                    Mouse wheel + Shift = Scroll left/right
                    <br />
                    Mouse wheel = Scroll up/down
                    <br />
                    Shift + KEY on event = Link to Asana
                    <Divider sx={{my: "5px"}}/>
                    <div className="b-fa b-fa-plus">&nbsp;&nbsp;-&nbsp;&nbsp;Created event</div>
                    <br />
                    <div className="b-fa b-fa-pen">&nbsp;&nbsp;-&nbsp;&nbsp;Updated event</div>
                    <br />
                    <div className="b-fa b-fa-sticky-note">&nbsp;&nbsp;-&nbsp;&nbsp;Event with notes</div>
                    <Divider sx={{my: "5px"}}/>
                    <Box sx={{display: "flex", justifyContent: "start", mb: "5px"}}>
                        <Box className="help_button__from_calendar_icon " />
                        <Box>&nbsp;&nbsp;-&nbsp;&nbsp;Push events changes from Calendar</Box>
                    </Box>
                    <Box sx={{display: "flex", justifyContent: "start"}}>
                        <Box className="help_button__from_asana_icon" />
                        <Box>&nbsp;&nbsp;-&nbsp;&nbsp;Push events changes from Asana</Box>
                    </Box>
                </Typography>

            </Popover>
        </>
    )
}

export default HelpButton
