import {FETCH_CALENDAR_CONFIG} from "../types";


const initialStateTimeConfig = {timeConfig : {}, templates: []}
export const configReducer = (state = initialStateTimeConfig, action) => {
    switch (action.type) {
        case FETCH_CALENDAR_CONFIG:
            return  action.payload;
        default: return state
    }
};