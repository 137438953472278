import {isExists} from "../utils";
import {CSSHelper, DateHelper, TimeSpan} from "@bryntum/scheduler/scheduler.umd";
import {MONGO_STATUS, SYNC_ASANA_STATUS} from "../constants";

export function updateSyncFromAsanaStatus(scheduler, data) {
    let records = scheduler.eventStore.query(record => isExists(record.mongoStatus) && record.mongoStatus !== MONGO_STATUS.NOT_MODIFIED);

    if (data.syncFromAsanaStatus === SYNC_ASANA_STATUS.IN_SYNC) {
        records.forEach(record => {
            record.setAsync("isSyncFromAsana", true)

        })
    } else {
        records.forEach(record => {
            record.setAsync("isSyncFromAsana", false)
        })
    }
}

export function getLiveOpsTemplateInfoNameIfExist(templates, eventRecord) {
        if (!isExists(eventRecord?.template)) return "no template"
        let resourceName = eventRecord?.resource?.name
        let templateName = templates?.[resourceName]?.[eventRecord?.template];
        return isExists(templateName)? templateName: "unknown template"
}
export function getCommunityTemplateInfoNameIfExist(templates, eventRecord) {
    if (!isExists(eventRecord?.template)) return "no template"
    let templateName = templates?.[eventRecord?.template];
    return isExists(templateName)? templateName: "unknown template"
}
export function getRangeDayForCopyEvent(eventRecord, eventStore =[]) {
    let copiedRecords = eventStore.query(record => record.isCopied)
    let days = 0
    if (copiedRecords.length > 1 ) {
        copiedRecords.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
        let firstCopiedEvent = copiedRecords[0]
        if (eventRecord.originalID !== firstCopiedEvent.id) {
            let copiedEvent = copiedRecords.find(record => record.id === eventRecord.originalID);
            if (copiedEvent) {
                days = DateHelper.diff(firstCopiedEvent.startDate, copiedEvent.startDate, 'd')
            }
        }
    }
    return days
}
export function clearCopiedFieldForEvents( eventStore =[]) {
    let copiedRecords = eventStore.query(record => record.isCopied)
    copiedRecords.forEach(record => record.isCopied = false)
}

export function parseDate(date) {
    return DateHelper.format(DateHelper.parse(date), 'YYYYMMDD');
}
export function parseDate2(date) {
    return DateHelper.format(DateHelper.parse(date), 'DD.MM');
}

export const replaceOrAppendDate = (record) => {
    debugger
    const date = parseDate2(record.startDate);
    let regex = /\(\d{2}\.\d{2}\)/;
    if (regex.test(record.name)) {
        return record.name.replace(regex, `(${date})`);
    } else {
        return `${record.name} (${date})`;
    }
}
export function setTimeToDate(date, time = {hours: 0, minutes: 0}) {

    let newDate = DateHelper.parse(date, "YYYY-MM-DD");
    DateHelper.set(newDate, "h", time.hours);
    DateHelper.set(newDate, "m", time.minutes);
    DateHelper.set(newDate, "s", 0);
    return newDate;
}

export function copyHours(date, source) {
    let newDate = DateHelper.parse(date);
    console.log('copyHours')
    console.log(newDate)
    if(source != undefined)
        DateHelper.set(newDate, "h", DateHelper.parse(source).getHours());
    else
        DateHelper.set(newDate, "h", 0);

    DateHelper.set(newDate, "m", 0);
    DateHelper.set(newDate, "s", 0);
    return newDate;
}

export function getEventColorByConfigs(eventRecord, resourceRecord) {
    if (!isExists(eventRecord.eventColor)) {
        let eventConfig = resourceRecord.eventNameColors.filter(eventNameColor => eventRecord.name.startsWith(eventNameColor.name));
        if (isExists(eventConfig) && eventConfig.length > 0) {
            return eventConfig[0].colorsParams
        } else if (isExists(resourceRecord.resourceHexColor)) {
            return resourceRecord.resourceHexColor
        }
    }
}

export function getEventColorFromConfigs(eventRecord, resourceRecord) {
    if (isExists(eventRecord.eventColor)) {
        return eventRecord.eventColor
    } else {
        let configColor =  getEventColorByConfigs(eventRecord, resourceRecord)
        if (isExists(configColor)) {
            return getEventColorByConfigs(eventRecord, resourceRecord)
        } else {
            return resourceRecord.eventColor
        }
    }
}



export function setStylesForNewEvents(eventRecord, resourceRecord, colorConfig) {
    if (!isExists(eventRecord.eventColor)) {
        if(colorConfig != undefined) {
            let colorKey = Object.keys(colorConfig).filter(color => eventRecord.name.indexOf(color)>=0)
            if(colorKey.length>0) eventRecord.eventColor = colorConfig[colorKey]
            return
        }
        let eventConfig = resourceRecord.eventNameColors.filter(eventNameColor => eventRecord.name.startsWith(eventNameColor.name));
        if (isExists(eventConfig) && eventConfig.length > 0) {
            eventRecord.eventColor = eventConfig[0].colorsParams
        } else if (isExists(resourceRecord.resourceHexColor)) {
            eventRecord.eventColor = resourceRecord.resourceHexColor
        }
    }

}
export function setCustomEventStyles(eventRecord, colorParams) {
    let backgroundColor = colorParams[0] + ", " + colorParams[1] + "%, " + (colorParams[2] + 35) + "%";
    let color = colorParams[0] + ", " + colorParams[1] + "%, " + colorParams[2] + "%";
    let nameCss = "._" + eventRecord.id;
    if (eventRecord.parentRelease === undefined) {
        CSSHelper.insertRule(`${nameCss} { background-color: hsl(${backgroundColor})!important; border-color: hsl(${color})!important;  color: #000000!important; }`);
        CSSHelper.insertRule(`${nameCss}.b-sch-event-selected { background-color: hsl(${color})!important; color: white!important;}`);
    } else {
        CSSHelper.insertRule(`${nameCss} { border-color: hsl(${color})!important; }`)

    }
}

export function setEventTimeRange (scheduler, eventRecord)  {
    const nextIndex = scheduler.current.schedulerInstance.features.timeRanges.store.getCount();
    let element = [];
    if(eventRecord.note !== undefined && eventRecord.note !== '') {
        eventRecord.note.split("\n").forEach(row => {
            element.push(`${row}<br>`)
        });
    }
    scheduler.current.schedulerInstance.features.timeRanges.store.insert(nextIndex,
        {
            "id"        : 'selected-event',
            "startDate" : eventRecord.startDate,
            "endDate"   : eventRecord.endDate,
            "name"      : '',
            "cls": 'selected-time-range',
            "info": eventRecord.note !== '' ?  `<div>` + element.join("") +`</div>`: eventRecord.name
        });
}

export function addImportantDatesTimeRange (scheduler, eventRecord)  {
    const timeRage = scheduler.current.schedulerInstance.features.timeRanges.store.getById(eventRecord.id);
    if (timeRage === undefined && !eventRecord.data.id.includes("_generated")) {
        const nextIndex = scheduler.current.schedulerInstance.features.timeRanges.store.getCount();
        let element = [];
        if(eventRecord.note !== undefined && eventRecord.note !== '') {
            eventRecord.note.split("\n").forEach(row => {
                element.push(`${row}<br>`)
                });
        }
        scheduler.current.schedulerInstance.features.timeRanges.store.insert(nextIndex,
            {
                "id": eventRecord.id,
                "startDate": eventRecord.startDate,
                "endDate": eventRecord.endDate,
                "name": '',
                "cls": 'time-range',
                "info": eventRecord.note !== '' ?  `<div>` + element.join("") +`</div>`: eventRecord.name
            });
    }

}
export function hideImportantDatesTimeRange (scheduler, eventRecord)  {
    const selectedEvent = scheduler.current.schedulerInstance.features.timeRanges.store.getById(eventRecord.id);
    scheduler.current.schedulerInstance.features.timeRanges.store.remove(selectedEvent);
}

export function hideEventTimeRange (scheduler)  {
    const selectedEvent = scheduler.current.schedulerInstance.features.timeRanges.store.getById('selected-event');
    scheduler.current.schedulerInstance.features.timeRanges.store.remove(selectedEvent);
}

export function processTimeAxisHeaderClick(scheduler, event) {
    let duration = DateHelper.getDurationInUnit(event.startDate, event.endDate, "d");
    if (duration === 1) {
        const recordId = event.type + event.startDate;
        let record = scheduler.current.schedulerInstance.project.timeRangeStore.getById(recordId)
        if (record === undefined) {
            scheduler.current.schedulerInstance.project.timeRangeStore.add({
                id: recordId,
                startDate: event.startDate,
                endDate: event.endDate,
                durationUnit: 'd',
                cls: "fika"
            })
        } else {
            scheduler.current.schedulerInstance.project.timeRangeStore.remove(record)
        }
    }
}