import {
    Alert,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, Grid,
    MenuItem,
    Select, TextField
} from "@mui/material";
import CalendarDatePicker from "./CalendarDatePicker";
import React, {Fragment, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {cloneCategory} from "../requests";
import {LoadingButton} from "@mui/lab";

export const OneYearCopyPopup = ({status, setStatus, game}) => {
    const categories = useSelector((state) => state.calendarCategories)

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [untilDate, setUntilDate] = useState(endDate)

    const [isPeriodChecked, setIsPeriodChecked] = useState(false)
    const [period, setPeriod] = useState(0)

    const [currentCategory, setCurrentCategory] = useState("")
    const [requestLoading, setRequestLoading] = useState(false)


    useEffect(() => {
        if (categories.length > 0) {
            setCurrentCategory(categories[0])
        }
    }, [categories])

    const dateChangeHandler = (startDate) => {
        setStartDate(startDate)
    }

    const endDateChangeHandler = (startDate) => {
        setEndDate(startDate)
    }

    const untilDateChangeHandler = (untilDate) => {
        setUntilDate(untilDate)
    }


    const handleClone = () => {
        setRequestLoading(true)
        const category = {currentCategory, startDate, endDate, isPeriodChecked, period, untilDate}
        cloneCategory(category, game, requestLoading);
        setRequestLoading(false);
        setStatus(false);
    }
    const handleChecked = (event) => {
        setIsPeriodChecked(event.target.checked)
    }

    return <Fragment>
        <Dialog open={status} onClose={() => setStatus(false)} fullWidth>
            <DialogTitle>Copying tasks for one year</DialogTitle>
            <DialogContent>
                <Select fullWidth
                        value={currentCategory}
                        onChange={(event) => setCurrentCategory(event.target.value)}
                >
                    {categories.map((category, index) => {
                        return <MenuItem key={index} value={category}>
                            {category}
                        </MenuItem>
                    })}
                </Select>
                        <CalendarDatePicker dateChangeHandler={dateChangeHandler}
                                            label="Choose the start day"
                                            date={startDate}
                                            styles={{"width": "49%", marginTop: "10px", marginRight: "5px"}}/>
                        <CalendarDatePicker dateChangeHandler={endDateChangeHandler}
                                            label="Choose the end day"
                                            date={endDate}
                                            styles={{"width": "49%", marginTop: "10px", marginLeft: "5px"}}/>
                        <CalendarDatePicker dateChangeHandler={untilDateChangeHandler}
                                            label="Repeat untill"
                                            date={untilDate}
                                            styles={{"width": "49%", marginTop: "10px", marginLeft: "5px"}}/>
            </DialogContent>
            {!isPeriodChecked &&
                <Alert severity="info">Default repetition period is 1 year</Alert>
            }
            <FormControlLabel sx={{ml:2}} control={
                <Checkbox value={isPeriodChecked}
                onChange={handleChecked}/>}
                              label="Custom repetition period for each event" />
            {isPeriodChecked && <TextField
                sx={{ mb: 1, ml: 3, mr: 3}}
                value={period}
                id={`period-in-days`} // Unique id attribute
                label="Repetition period (in days)"
                variant="outlined"
                color="warning"
                onChange={(event) => {
                    setPeriod(event.target.value)
                }}
                type="number"
                name={`period-in-days`} // Unique name attribute
            />}
            <DialogActions>
                <LoadingButton
                    onClick={handleClone}
                    loading={requestLoading}>
                    Clone
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </Fragment>


}