import {ChevronRight, ExpandMore, Remove} from "@mui/icons-material"
import {Checkbox, FormControlLabel} from "@mui/material"
import {grey} from "@mui/material/colors"
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {FETCH_USER, UPDATE_USER_GAME_CONFIGS} from "../redux/types"
import {isExists} from "../utils"

const ResourceColumn = ({config, record, game}) => {
    const [checked, setChecked] = useState(false)

    const user = useSelector((state) => state.user.currentUser)

    const dispatch = useDispatch()

    useEffect(() => {
        setCheckedAsync()
        eventFilterByResource()
    }, [])

    useEffect(() => {
        setColor(grey[checked ? 200 : 50])
    }, [checked])

    const setCheckedAsync = async () => {
        setChecked(config.hiddenResources.includes(record.id))
    }

    const eventFilterByResource = async () => {
        if (isExists(config) && isExists(record.eventStore)) {
            record.eventStore.filter({
                id: "resourceFilter",
                filterBy: (event) => !config.hiddenResources.includes(event.resourceId)
            })
        }
    }

    const setColor = (color = grey[200]) => {
        let checkboxElement = document.getElementById(`checkbox-${record.id}`)
        if (!isExists(checkboxElement)) return false
        let resourceInfoElement = checkboxElement.closest(".b-resourceinfo-cell")
        if (!isExists(resourceInfoElement)) return false
        if (isExists(resourceInfoElement.style)) resourceInfoElement.style.background = color

    }

    const handleChange = ({currentTarget}) => {
        const data = Object.assign({}, config)
        if (config.hiddenResources.includes(currentTarget.name)) {
            data.hiddenResources.splice(data.hiddenResources.indexOf(currentTarget.name), 1)
        } else {
            data.hiddenResources.push(currentTarget.name)
        }

        updateUserConfigs(data).catch(console.error)

        setCheckedAsync().catch(console.error)
        eventFilterByResource().catch(console.error)
    }

    const updateUserConfigs = async (data) => {
        const userCopy = Object.assign({}, user)
        userCopy.gameConfigs.forEach(config => {
            if (config.selectedGame === game) {
                config.hiddenResources = data.hiddenResources
            }
        })
        dispatch({type: UPDATE_USER_GAME_CONFIGS, payload: userCopy.gameConfigs.find(gameConfig => gameConfig.selectedGame === game)})
        dispatch({type: FETCH_USER, payload: userCopy})
    }

    return (
        <FormControlLabel
            control={
                <Checkbox
                    id={`checkbox-${record.id}`}
                    color="primary"
                    name={record.id}
                    checked={!checked}
                    onChange={handleChange}
                    icon={
                        <ChevronRight
                            fontSize="small"
                            color="action"
                        />
                    }
                    checkedIcon={
                        <ExpandMore
                            fontSize="small"
                            color="action"
                        />
                    }
                    indeterminateIcon={
                        <Remove
                            fontSize="small"
                            color="disabled"
                        />
                    }
                />
            }
            label={record.name}
        />
    )
}

export default ResourceColumn
