import {initWebSocket} from "../../websocket";
import {isExists} from "../../utils";

export const INIT_WEBSOCKET = 'INIT_WEBSOCKET';
export const DISCONNECT_WEBSOCKET = 'DISCONNECT_WEBSOCKET';

export const websocketReducer = (state = null, action) => {

    switch (action.type) {
        case INIT_WEBSOCKET:
            console.log("INIT_WEBSOCKET")
            let client = initWebSocket(action.payload.scheduler, action.payload.game)
            console.log("FINISH INIT_WEBSOCKET")
            return client;
        case DISCONNECT_WEBSOCKET: {
            if (isExists(state)) {
                console.log("DISCONNECT_WEBSOCKET")
                state.forceDisconnect()
                state.deactivate()
            }

            return state
        }
        default:
            return state
    }
};
