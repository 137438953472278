import React, {useCallback, useEffect, useState} from 'react';
import handleOfferEventChange from "../handlers/handleOfferEventChange"
import manageCalendar from "./manageCalendar";
import {useDispatch, useSelector} from "react-redux";
import {
    decodeBase64,
    hasText,
    isEmptyArray,
    isExists,
    propertyIsArray,
    propertyIsObject,
    setHoursForEventDates
} from "../utils"
import {
    getAsanaSyncTasksInfo,
    getAsanaSyncTasksProgress,
    pushEventChangesInAsana,
    sendAddEvent,
    sendRemoveEvent,
    sendUpdateEvent
} from "../requests";
import {DateHelper, Mask, MessageDialog, ObjectHelper, StringHelper} from "@bryntum/scheduler/scheduler.umd";
import {
    EVENT_TYPE,
    SELECTED_GAME
} from "../constants";
import {labelsFeature} from "./bryntum_configs/labelsFeature";
import BryntumEvent from "../BryntumEvent";
import {removeSyncResourcesAction} from "../redux/sync-resources/actions";
import {BryntumScheduler} from "@bryntum/scheduler-react";

import EditNotePopup from "../components/EditNotePopup";
import Popup from "../elements/Popup";
import ReleaseCreatePopup from "../components/ReleaseCreatePopup";
import ReleaseEditPopup from "../components/ReleaseEditPopup";
import {INIT_WEBSOCKET} from "../redux/websocket/websocketReducer";
import {eventClick} from "./bryntum_configs/eventClick";
import {
    addImportantDatesTimeRange,
    clearCopiedFieldForEvents,
    getLiveOpsTemplateInfoNameIfExist,
    getRangeDayForCopyEvent,
    getTimeSpan, hideEventTimeRange, hideImportantDatesTimeRange, processTimeAxisHeaderClick,
    setEventTimeRange
} from "./bryntum_helpers";
import store from "../store";
import TemplatePopup from "../components/TemplatePopup";
import DeletePopup from "../components/DeletePopup";
import {decodeResourceId} from "./bryntum_configs/compositeEventUtils";
import {fetchLabelsConfig} from "../redux/labels-config/actions";
import {fetchCalendarCategories} from "../redux/categories/actions";

let startDate = new Date();
startDate.setDate(startDate.getDate() - 60);
let endDate = new Date();
endDate.setDate(endDate.getDate() + 365);

const RELEASES = {
    "DEV версия": ['BC апрув', 'RC апрув'],
    "GP Сабмит/Релиз": ['Release: Google Play', 'Submit: GP'],
    "iOS Сабмит/Релиз": ['Release: iOS', 'Submit: iOS'],
    "AM Сабмит/Релиз": ['Release: Amazon', 'Submit: Amazon'],
    "WS Сабмит/Релиз": ["Submit: WS", "Release: WS"],
    "Hard/Soft Update": ['Soft Update после релиза', 'Hard Update после релиза'],
};

const SCCalendar = (props) => {

    const [deleteRecords, setDeleteRecords] = useState([]);
    const user = useSelector((state) => state.user.currentUser);
    const labelsConfig = useSelector((state) => state.labelsConfig);


    let timeInStartDate = 0;

    let [existsInAsana, setExistsInAsana] = useState(false);

    const [currentRecord, setCurrentRecord] = useState(null);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchLabelsConfig("ba"))
        dispatch(fetchCalendarCategories("sc"))

    }, [])
    const getEditorConfigs = () => {
        return {
            multiEventSelect: true,
            eventDragFeature: {
                constrainDragToResource: true
            },
            rowCopyPasteFeature: false,
            eventResizeFeature: true,
            eventMenuFeature: {
                items: {
                    editEventNote: {
                        text: 'Edit event note',
                        icon: "b-fa b-fa-fw b-fa-sticky-note",
                        onItem({eventRecord}) {
                            props.showNoteEditor(eventRecord)
                        }
                    },
                    eventLink: {
                        text: 'Link to Asana',
                        icon: "b-fa b-fa-fw b-fa-external-link-alt",
                        weight: 0,
                        onItem({eventRecord}) {
                            if (hasText(eventRecord.link)) {
                                window.open(eventRecord.link, "_blank")
                            }
                        }
                    },
                    copyLink: {
                        text: 'Copy link',
                        icon: "b-fa b-fa-fw b-icon-copy",
                        weight: 0,
                        onItem({eventRecord}) {
                            if (hasText(eventRecord.link)) {
                                navigator.clipboard.writeText(eventRecord.link).then();
                            }
                        }
                    },
                    pushToAsana: {
                        text: 'Push to Asana',
                        icon: "b-fa b-fa-fw b-fa-angle-right",
                        onItem({eventRecord}) {
                            pushEventChangesInAsana(eventRecord, SELECTED_GAME.SC.toLowerCase())
                        }
                    },
                    managePromo: {
                        text: 'Add to promo',
                        type: "checkbox",
                        checked: false,
                        icon: "b-fa b-fa-fw",
                        onAction(item) {
                            if (item.checked) {
                                let currentRecord = item.source.eventRecord;
                                let promoResource = props.scheduler.current.schedulerInstance.resourceStore.findRecord("name", "LiveOps Promo");
                                let clonedCurrentRecord = ObjectHelper.clone(currentRecord.data);
                                clonedCurrentRecord.id = clonedCurrentRecord.id + "_promo";
                                clonedCurrentRecord.resourceId = promoResource.id;
                                clonedCurrentRecord.type = EVENT_TYPE.PROMO;
                                clonedCurrentRecord.resizable = false;
                                props.scheduler.current.schedulerInstance.eventStore.addAsync(clonedCurrentRecord);
                                if (item.source.eventRecord.data.type === "ab-test") {
                                    currentRecord.setAsync("promo", true)
                                    sendAddEvent(clonedCurrentRecord, SELECTED_GAME.SC.toLowerCase());
                                } else {
                                    clonedCurrentRecord.link = "";
                                    currentRecord.setAsync("promo", true)
                                    currentRecord.setAsync("promoTemplate", "")
                                    currentRecord.setAsync("promoTemplateName", "")
                                    currentRecord.setAsync("type", "promo")
                                    setCurrentRecord(item.source.eventRecord);
                                    props.showPopup("chooseTemplate")
                                }
                            } else {
                                let currentRecord = item.source.eventRecord;
                                let promoRecord = props.scheduler.current.schedulerInstance.eventStore.findRecord("id", currentRecord.id + "_promo");
                                currentRecord.setAsync("promo", false);

                                props.scheduler.current.schedulerInstance.eventStore.remove(promoRecord.id);
                                let clonedCurrentRecord = ObjectHelper.clone(promoRecord);
                                clonedCurrentRecord.id = currentRecord.id;
                                sendRemoveEvent(promoRecord, SELECTED_GAME.SC.toLowerCase())
                            }
                        }
                    },
                    offerEvent: {
                        text: "Offer",
                        type: "checkbox",
                        checked: false,
                        disabled: true,
                        hidden: true,
                        allowedTypes: [EVENT_TYPE.AB_TEST, EVENT_TYPE.EVENT],
                        onChange: ({source: {event}, checked}) => handleOfferEventChange(
                            props.scheduler.current.schedulerInstance.resourceStore,
                            props.scheduler.current.schedulerInstance.eventStore,
                            event,
                            checked
                        )
                    },
                    cutEvent: false
                },
                processItems({eventRecord, items}) {
                    if (propertyIsObject(items, "offerEvent")) {
                        items.offerEvent.event = eventRecord
                        items.offerEvent.checked = eventRecord.offer
                    }

                    Object
                        .values(items)
                        .filter(item => propertyIsArray(item, "allowedTypes"))
                        .forEach(item => {
                            const notAllowed = !item.allowedTypes.includes(eventRecord.type)
                            item.disabled = notAllowed
                            item.hidden = notAllowed
                        })

                    items.managePromo.eventRecord = eventRecord

                    function checkPromoRecord() {
                        if (eventRecord.promo) {
                            items.managePromo.checked = true;
                        }
                    }

                    checkPromoRecord();
                    if (eventRecord.isArchive) {
                        items.editEventNote.hidden = true;
                        items.editEvent.hidden = true;
                        items.deleteEvent.hidden = true;
                        items.copyEvent.hidden = true;
                        items.managePromo.hidden = true;
                        items.offerEvent.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.HOLIDAY) {
                        items.editEventNote.hidden = true;
                        items.managePromo.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.RELEASE) {
                        items.copyEvent.hidden = true;
                        items.managePromo.hidden = true;
                        if (isExists(eventRecord.parentRelease)) {
                            items.managePromo.hidden = true;
                        }
                    } else if (eventRecord.type === EVENT_TYPE.ASO) {
                        items.copyEvent.hidden = true;
                        items.managePromo.hidden = true;
                        items.deleteEvent.hidden = false
                    } else if (eventRecord.type === EVENT_TYPE.PROMO || eventRecord.type === EVENT_TYPE.OFFER) {
                        items.editEventNote.hidden = true;
                        items.editEvent.hidden = true;
                        items.deleteEvent.hidden = true;
                        items.copyEvent.hidden = true;
                        items.managePromo.hidden = true;
                        items.offerEvent.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.COMMUNITY) {
                        items.managePromo.hidden = true;
                        items.offerEvent.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.FEATURING) {
                        items.managePromo.hidden = true;
                        items.offerEvent.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.IMPORTANT_DATES) {
                        items.managePromo.hidden = true;
                    }
                    if (!isExists(eventRecord.link)) {
                        items.eventLink.disabled = true;
                    }
                    props.setStatusForPushToAsanaEventContextMenu(eventRecord, items)
                }
            },
            listeners: {
                beforeEventAdd: ({eventRecord, source}) => {
                    if (eventRecord.isCreating) {
                        source.eventStore.remove(eventRecord)
                    }
                },
                beforeEventDelete: (source) => processBeforeEventDelete(source),
                beforeEventEdit: (source) => {
                    source.eventRecord.resourceId = source.resourceRecord.id;
                    let eventRecord = source.eventRecord;
                    let resourceRecord = source.resourceRecord;
                    if (isExists(source.eventRecord.parentRelease)) {
                        eventRecord = props.scheduler.current.schedulerInstance.eventStore.getById(source.eventRecord.parentRelease);
                    }
                    if (!eventRecord.isArchive &&
                        resourceRecord.type !== EVENT_TYPE.PROMO && resourceRecord.type !== EVENT_TYPE.OFFER) {
                        showEditor(eventRecord, resourceRecord);
                    }
                    return false;
                },
                beforeEventDrag: ({eventRecord}) => {
                    if (!eventRecord.isArchive) {
                        if (eventRecord.type !== EVENT_TYPE.PROMO && eventRecord.type !== EVENT_TYPE.OFFER) {
                            return true
                        }
                    }
                    return false
                },
                beforeEventResize: ({eventRecord}) => {
                    timeInStartDate = eventRecord.data.startDate.getHours();
                    if (!eventRecord.isArchive) {
                        if ([EVENT_TYPE.EVENT, EVENT_TYPE.AB_TEST,
                            EVENT_TYPE.FEATURING, EVENT_TYPE.AB_TEST_COMMENT, EVENT_TYPE.COMMUNITY].includes(eventRecord.type) &&
                            decodeBase64(eventRecord.resourceId) === "Новости"
                        ) {
                            return true
                        }
                    }
                    return false
                },
                timeAxisHeaderClick: (event) => {
                    processTimeAxisHeaderClick(props.scheduler, event);
                },
                eventSelectionChange: ({action, selected, selection}) => {
                    let currentSelectedEvent = selected[0];
                    if (selection.length > 1) {
                        if (action === "select") {
                            let firstSelectedEvent = selection[0];
                            if (currentSelectedEvent.data.resourceId !== firstSelectedEvent.data.resourceId) {
                                props.scheduler.current.schedulerInstance.deselectEvent(currentSelectedEvent)
                            }
                        }
                    }
                },
                eventDrop: (event) => {
                    const originalStart = event.context.origStart;
                    const originalStartDay = DateHelper.get(originalStart, "d");
                    event.eventRecords.forEach((eventRecord) => {
                        const newStartDay = DateHelper.get(eventRecord.startDate, "d")
                        if (originalStartDay !== newStartDay) {
                            DateHelper.copyTimeValues(eventRecord.startDate, originalStart)
                            DateHelper.copyTimeValues(eventRecord.endDate, originalStart)
                        }
                        if (!eventRecord.isCopy) {
                            sendUpdateEvent(eventRecord, SELECTED_GAME.SC.toLowerCase());
                        }
                    })

                    props.scheduler.current.schedulerInstance.refreshRows();
                },
                beforeCopy: (event) => {

                    clearCopiedFieldForEvents(event.source.eventStore)
                    let disabledEvent = event.records.find(record => [EVENT_TYPE.ASO, EVENT_TYPE.OFFER, EVENT_TYPE.PROMO].includes(record.type));
                    if (isExists(disabledEvent)) {
                        return false;
                    } else {
                        event.records.forEach(record => {
                            record.isCopied = true
                            record.originalID = record.id
                            record.copiedTime = DateHelper.get(record.startDate, 'h')
                        })
                        return true
                    }
                },
                beforePaste: (event) => {
                    if (event.resourceRecord.id === event.records[0].resourceId) {
                        return isExists(event.date)
                    } else {
                        return false
                    }
                },
                eventResizeEnd: ({eventRecord}) => {
                    DateHelper.copyTimeValues(eventRecord.endDate, eventRecord.startDate);
                    eventRecord.duration = DateHelper.diff(eventRecord.startDate, eventRecord.endDate, 'd');
                    setHoursForEventDates(eventRecord, timeInStartDate);
                    sendUpdateEvent(eventRecord, SELECTED_GAME.SC.toLowerCase());
                    hideEventTimeRange(props.scheduler);
                },
                eventClick: ({event, eventRecord}) => {
                    setEventTimeRange(props.scheduler, eventRecord);
                    return eventClick(event, eventRecord)
                },
                scheduleClick: () => {
                    hideEventTimeRange(props.scheduler);
                }
            },

            scheduleMenuFeature: {
                processItems({resourceRecord, items}) {
                    if (isExists(items.addEvent)) {
                        items.addEvent.disabled = false
                    }

                    if (resourceRecord.type === EVENT_TYPE.ASO || resourceRecord.type === EVENT_TYPE.PROMO || resourceRecord.type === EVENT_TYPE.OFFER || resourceRecord.type === undefined) {
                        items.addEvent.hidden = true;
                        if (items.pasteEvent !== undefined) {
                            items.pasteEvent.hidden = true;
                        }

                    } else if (resourceRecord.type === EVENT_TYPE.RELEASE) {
                        if (items.pasteEvent !== undefined) {
                            items.pasteEvent.hidden = true;
                        }
                    }
                }
            },
            eventDragSelectFeature: true,
            eventDragCreateFeature: false
        }}


    const processBeforeEventDelete = ({eventRecords}) => {
        const deletedEvents = eventRecords.map(
            (eventRecord) => isExists(eventRecord.parentRelease)
                ? props.eventStore.getById(eventRecord.parentRelease)
                : eventRecord
        )
        setDeleteRecords(deletedEvents);
        setExistsInAsana(!!eventRecords[0].data.link);
        props.showPopup("deleteEvents");
        return false;
    }

    const showEditor = useCallback((eventRecord, resourceRecord) => {
        eventRecord.type = resourceRecord.data.type;

        let popupData
        if (resourceRecord.data.type === EVENT_TYPE.EVENT) {
            popupData = {hasTime: true, type: EVENT_TYPE.EVENT, hasEndDate: true}
        } else if ([EVENT_TYPE.HOLIDAY, EVENT_TYPE.FEATURING, EVENT_TYPE.IMPORTANT_DATES].includes(resourceRecord.data.type)) {
            popupData = {hasTime: false, type: "default", hasEndDate: true}
        } else if ([EVENT_TYPE.ASO].includes(resourceRecord.data.type)) {
            popupData = {hasTime: false, type: "aso", hasEndDate: false}
        } else if ([EVENT_TYPE.COMMUNITY].includes(resourceRecord.data.type)) {
            popupData = {hasTime: false, type: "default", hasEndDate: true};
            if (["Новости"].includes(resourceRecord.data.name))  {
                popupData = {hasTime: false, type: "community", hasEndDate: true}
            }
        } else if (eventRecord.hasGeneratedId && resourceRecord.data.type === EVENT_TYPE.RELEASE) {
            popupData = "releaseCreate"
        } else if (!eventRecord.hasGeneratedId && resourceRecord.data.type === EVENT_TYPE.RELEASE) {
            popupData = "releaseEdit"
        } else {
            popupData = {hasTime: true, type: "default",  hasEndDate: true}
        }
        eventRecord.status = eventRecord.hasGeneratedId ? "create" : "update";
        props.setResourceRecord(resourceRecord);
        props.setEventRecord(eventRecord);
        props.showPopup(popupData);
    }, []);


    function getConfigs() {
        let overrideConfigs = {
            eventTooltipFeature: {

                template: data => {

                    function getEventStatus() {
                        let arrayStatusText = [];
                        data.eventRecord.isArchive && arrayStatusText.push(`archive event`);
                        return !isEmptyArray(arrayStatusText) ? `<div><b>Status:</b> ${arrayStatusText.join(", ")}</div>` : ""
                    }

                    function getTemplateInfo() {
                        const templatesByGame = store.getState()?.calendarConfig?.templates;
                        let templates = templatesByGame?.find(config => (config.game === SELECTED_GAME.SC && config.type === EVENT_TYPE.EVENT))?.templateGidsByName
                        if (data.eventRecord.type === EVENT_TYPE.EVENT) return getLiveOpsTemplateInfoNameIfExist(templates, data.eventRecord);
                        return !isExists(data.eventRecord?.template)?  "no template": "unknown template"
                    }

                    return `<div>
                ${getEventStatus()}
                ${(data.eventRecord.name !== undefined) ? `<div><b>Name</b>: ${(data.eventRecord.name) + ((data.eventRecord.parentRelease !== undefined && data.eventRecord.parentRelease !== null) ? "%" : "")}</div>` : ''}
                <div><b>Template name</b>: ${(getTemplateInfo())}</div>
                ${(data.eventRecord.eventId !== undefined && data.eventRecord.eventId !== null && data.eventRecord.eventId.length !== 0) ? `<div><b>Event ID</b>: ${data.eventRecord.eventId}</div>` : ''}
                ${(data.eventRecord.levelsWW !== undefined && data.eventRecord.levelsWW !== null && data.eventRecord.levelsWW.length !== 0) ? `<div><b>Levels WW</b>: ${data.eventRecord.levelsWW}</div>` : ''}
                ${(data.eventRecord.levelsBR !== undefined && data.eventRecord.levelsBR !== null && data.eventRecord.levelsBR.length !== 0) ? `<div><b>Levels BR</b>: ${data.eventRecord.levelsBR}</div>` : ''}
                ${(data.eventRecord.levelsPL !== undefined && data.eventRecord.levelsPL !== null && data.eventRecord.levelsPL.length !== 0) ? `<div><b>Levels PL</b>: ${data.eventRecord.levelsPL}</div>` : ''}
                ${(data.eventRecord.persent !== undefined && data.eventRecord.persent !== null) ? `<div><b>Persent</b>: ${data.eventRecord.persent}%</div>` : ''}
                ${(data.eventRecord.countries !== undefined && data.eventRecord.countries !== null && data.eventRecord.countries.length !== 0) ? `<div><b>Countries</b>: ${data.eventRecord.countries}</div>` : ''}
                ${(data.eventRecord.note !== undefined && data.eventRecord.note !== null && data.eventRecord.note.length !== 0) ? `<div><b>Note</b>: ${data.eventRecord.note}</div>` : ''}
        </div>`
                }
            },
            labelsFeature: {
                ...labelsFeature,
                left: {
                    renderer({domConfig, eventRecord}) {
                        const getClassByMongoStatus = () => {
                            switch (eventRecord.mongoStatus) {
                                case "CREATED":
                                    return "b-fa b-fa-plus"
                                case "UPDATED":
                                    return "b-fa b-fa-pen"
                                default:
                                    return ""
                            }
                        }
                        const getClassByNoteStatus = () => {
                            if (hasText(eventRecord.note)) {
                                return "b-fa b-fa-fw b-fa-sticky-note"
                            }
                            return ""
                        }
                        if (eventRecord.duration === 1) {
                            if (eventRecord.parentRelease !== undefined) {
                                let className = "b-sch-label-none";
                                domConfig.className[className] = true;
                            } else if (eventRecord.nestedIdEvents !== undefined) {
                                let className = "b-sch-label-left-release-parent";
                                domConfig.className[className] = true
                            }
                            return `<div>${eventRecord.name}&nbsp
                    ${hasText(getClassByMongoStatus()) ? `<div class="${getClassByMongoStatus()}"></div>` : ""}
                    ${hasText(getClassByNoteStatus()) ? `<div class="${getClassByNoteStatus()}"></div>` : ""}
                    </div>`

                        } else {
                            let className = "b-sch-label-none";
                            domConfig.className[className] = true;
                        }
                    }
                }
            },
            crudManager: {
                autoLoad: true,
                autoSync: false,
                autoCommit: true,
                phantomIdField: "phantomId",
                writeAllFields: true,
                transport: {
                    load: {
                        url: "/configs?game=sc",

                        method: 'GET',
                    }
                },
                eventStore:
                    {
                        modelClass: BryntumEvent,
                        listeners: {
                            beforeAdd: (event) => {
                                // For Copy/Paste feature
                                const eventRecord = event.records[0]
                                if (![EVENT_TYPE.PROMO, EVENT_TYPE.ASO, EVENT_TYPE.OFFER].includes(eventRecord.type)) {
                                    if (isExists(eventRecord.resource)) {
                                        const prevResource = eventRecord.resource.data.id
                                        const newResource = eventRecord.data.resourceId

                                        if (prevResource === newResource) {
                                            let days  = getRangeDayForCopyEvent(eventRecord, event.source.eventStore)
                                            eventRecord.startDate = DateHelper.add(eventRecord.startDate, days, 'd')
                                            if (isExists(eventRecord.copiedTime)) {
                                                DateHelper.set(eventRecord.startDate, "h", eventRecord.copiedTime);
                                                DateHelper.set(eventRecord.endDate, "h", eventRecord.copiedTime);
                                            }
                                            eventRecord.link = null
                                            sendAddEvent(eventRecord, SELECTED_GAME.SC.toLowerCase())
                                            return true
                                        }
                                    }
                                }
                            }
                        }
                    },
                listeners: {
                    requestDone: ({response}) => {

                        if (isExists(props.scheduler.current)) {

                            props.scheduler.current.schedulerInstance.zoomToLevel(
                                props.scheduler.current.schedulerInstance.viewPreset.data.parentIndex
                            )

                            // TODO: Update with websockets
                            if (response.hasOwnProperty("requestId")) {
                                dispatch(removeSyncResourcesAction(response.requestId))
                            }
                        }
                    },
                    load: () => {
                        if (isExists(props.scheduler.current)) {
                            Mask.mask("Соединение... Это может занять до 20 сек.")
                            dispatch({type: INIT_WEBSOCKET, payload: {scheduler: props.scheduler.current.schedulerInstance, game: SELECTED_GAME.SC.toLowerCase()}})
                            getAsanaSyncTasksInfo(SELECTED_GAME.SC.toLowerCase(), props.scheduler.current.schedulerInstance)
                                .then(() => Mask.unmask())
                            getAsanaSyncTasksProgress(SELECTED_GAME.SC.toLowerCase())
                        }

                    }
                }
            }
        }
        return props.getBryntumConfigs(getEditorConfigs, overrideConfigs);
    }

    return (
        <>{isExists(user.email) &&
        <>
            <BryntumScheduler
                ref={props.scheduler}
                {...getConfigs()}

            />
            {(props.popupShown && typeof props.popupShown === "object") &&
            <Popup
                labelsConfig={labelsConfig}
                eventRecord={props.eventRecord}
                eventStore={props.eventStore}
                resourceRecord={props.resourceRecord}
                onSave={props.handleClickSave}
                onClose={props.hideEditor}
                popupData={props.popupShown}
                game={SELECTED_GAME.SC}
                scheduler={props.scheduler.current.schedulerInstance}

            />
            }
            {props.popupShown === "noteEdit" && (
                <EditNotePopup
                    eventRecord={props.eventRecord}
                    eventStore={props.eventStore}
                    game ={SELECTED_GAME.SC}
                    onClose={props.hideEditor}
                />
            )}
            {props.popupShown === "releaseCreate" && (
                <ReleaseCreatePopup
                    closePopup={props.hideEditor}
                    eventRecord={props.eventRecord}
                    eventStore={props.eventStore}
                    dependencyStore={props.dependencyStore}
                    resourceRecord={props.resourceRecord}
                    onSave={props.handleClickSave}
                    onClose={props.hideEditor}
                    onSaveChild={props.handleClickSaveChildRelease}
                    releaseNames={RELEASES}
                    game={SELECTED_GAME.SC}
                />
            )}
            {props.popupShown === "releaseEdit" && (
                <ReleaseEditPopup
                    closePopup={props.hideEditor}
                    eventRecord={props.eventRecord}
                    eventStore={props.eventStore}
                    dependencyStore={props.dependencyStore}
                    resourceRecord={props.resourceRecord}
                    onSave={props.handleClickSave}
                    onClose={props.hideEditor}
                    onSaveChild={props.handleClickSaveChildRelease}
                    game={SELECTED_GAME.SC}
                />
            )}
            {props.popupShown === "chooseTemplate" && (
                <TemplatePopup
                    eventRecord={currentRecord}
                    game ={SELECTED_GAME.SC}
                    onClose={props.hideEditor}
                />
            )}
            {props.popupShown === "deleteEvents" && (
                <DeletePopup
                    deleteRecords={deleteRecords}
                    eventStore={props.eventStore}
                    selectedGame={SELECTED_GAME.SC}
                    existsInAsana={existsInAsana}
                    onClose={props.hideEditor}
                    scheduler={props.scheduler}
                />
            )}
        </>
        }
        </>);
};
export default manageCalendar(SCCalendar, SELECTED_GAME.SC)
