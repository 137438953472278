import React, {useState} from "react";

import './EditNotePopup.scss';
import {Button, Link, TextareaAutosize} from "@mui/material";
import {editEventNote} from "../requests";


export default function EditNotePopup(props) {

    const [popupState, setPopupState] = useState({
        ...props.eventRecord.data
    });



    const handleClickSave = () => {
        editEventNote(props.game, popupState)
        props.onClose()
    }


    function handleChange(event) {
        setPopupState((prevState) => ({
            ...prevState,
            note: event.target.value
        }));
    }

    return (
        <div className='popup-mask'>
            <div className='popup-note'>
                <header>
                    Edit note <Link
                        href={props.eventRecord.link}
                        target="_blank"
                        rel="noopener noreferrer">
                        {popupState.name}
                    </Link>
                </header>
                <TextareaAutosize className="popup-note-textarea" rowsMax={4} placeholder="note..."
                                  value={popupState.note}  onChange={(e) => handleChange(e)}/>
                <footer>
                    <Button variant="contained" color="primary" onClick={handleClickSave}>Save</Button>
                    <Button variant="contained" color="secondary" onClick={props.onClose}>Cancel</Button>
                </footer>
            </div>
        </div>
    )
}
