import React, {useCallback, useEffect, useRef, useState} from "react";
import Navbar from "../elements/Navbar";
import {isExists} from "../utils";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {MONGO_STATUS, SELECTED_GAME, USER_ROLE} from "../constants";

import {CSSHelper} from "@bryntum/scheduler/scheduler.umd";
import {Box} from "@mui/material";
import HelpButton from "../components/HelpButton";
import {pushBulkEventsToAsana, sendAddEvent, sendUpdateEvent} from "../requests";
import {DISCONNECT_WEBSOCKET} from "../redux/websocket/websocketReducer";
import {getCommonConfigs} from "./bryntum_configs/commonConfigs";
import {getViewerConfigs} from "./bryntum_configs/viewerConfigs";
import {setStylesForNewEvents} from "./bryntum_helpers";
import {getConfigByGame} from "../redux/userReducer";

const manageCalendar = (Calendar, game) => {

    return () => {
        const navigate = useNavigate()
        const user = useSelector((state) => state.user.currentUser);
        const colorConfig = useSelector((state) => state.calendarConfig.taskColorConfig)

        const schedulerRef = useRef();

        const [eventStore, setEventStore] = useState(null);
        const [dependencyStore, setDependencyStore] = useState(null);
        const [eventRecord, setEventRecord] = useState(null);
        const [resourceRecord, setResourceRecord] = useState(null);
        const [resourceStore, setResourceStore] = useState(null);
        const [popupShown, showPopup] = useState(undefined);
        const dispatch = useDispatch()
        const [selectedItems, setSelectedItems] = useState([])


        useEffect(() => {
                function initStore() {
                    const {eventStore, resourceStore, dependencyStore} = schedulerRef.current.schedulerInstance;
                    setEventStore(eventStore);
                    setResourceStore(resourceStore);
                    setDependencyStore(dependencyStore)
                }

                if (user.email !== undefined && isExists(schedulerRef.current)) {
                    let yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 7);
                    schedulerRef.current.schedulerInstance.scrollToDate(yesterday, {block: "start"});
                    CSSHelper.insertRule(`.b-sch-label-none { display: none!important}`);
                    initStore();
                }
            }, [user.email]
        );
        useEffect(() => {
            const listener = (e) => {
                if (e.key === "Escape") {
                    hideEditor()
                }
            }

            document.addEventListener("keydown", listener, false)

            return () => {
                document.removeEventListener("keydown", listener, false)
                dispatch({type: DISCONNECT_WEBSOCKET})
                handleSelectGame(SELECTED_GAME.NO_GAME)
            }
        }, [])


        const showNoteEditor = useCallback((eventRecord) => {
            //пример _generatedClassDefEx1
            // eventRecord.type = resourceRecord.data.type;
            setEventRecord(eventRecord);
            showPopup("noteEdit");
        }, []);

        function handleSelectGame(game) {
            navigate(`/${game.toLowerCase()}`)
        }

        const hideEditor = useCallback(() => {
            if (isExists(eventRecord)) {
                if (eventRecord.isCreating) {
                    eventStore.remove(eventRecord)
                }
            }

            showPopup(undefined)
            setEventRecord(null)
        }, [eventRecord, eventStore])

        const handleClickSave = (data) => {
            if (isExists(eventRecord)) {
                const changes = eventRecord.set({
                    ...data,
                    isCreating: false
                })

                if (isExists(changes)) {
                    if (eventRecord.hasGeneratedId) {
                        eventRecord.beginBatch()

                        setStylesForNewEvents(eventRecord, resourceRecord, colorConfig)

                        eventRecord.endBatch()

                        sendAddEvent(eventRecord, game.toLowerCase())
                    } else {
                        sendUpdateEvent(eventRecord, game.toLowerCase())
                    }
                }
            }

            hideEditor()
        }

        const handleClickSaveChildRelease = (data) => {
            if (data.hasGeneratedId) {
                data.beginBatch()
                setStylesForNewEvents(data, resourceRecord)

                data.endBatch()

                sendAddEvent(data, game.toLowerCase())
            } else {
                sendUpdateEvent(data, game.toLowerCase())
            }
        }
        const setStatusForPushToAsanaEventContextMenu = (eventRecord, items) => {
            if (eventRecord.mongoStatus === MONGO_STATUS.NOT_MODIFIED) {
                items.pushToAsana.hidden = true
            } else if (eventRecord.isSyncFromAsana) {
                items.pushToAsana.disabled = true
            }
        }

        async function handleSelectedItems() {
            await pushBulkEventsToAsana(selectedItems, game)
        }

        function groupPushDisabled(){
            if (selectedItems.length === 0) return true;

            let set = new Set(selectedItems.map(e => e.data.type))
            return set.size > 1;

        }

        const getBryntumConfigs = (getEditorConfigs, overrideConfigs = {}) => {
            const configs = {
                ...getCommonConfigs(schedulerRef, user, game),
                ...getConfigByGame(user, game).role === USER_ROLE.VIEWER? getViewerConfigs(schedulerRef): getEditorConfigs(),
                ...overrideConfigs
            }
            return configs;
        }
        return <Box className="container">

            <Navbar handleSelectGame={handleSelectGame} game={game}
                    handleSelectedItems = {handleSelectedItems}
                    groupPushDisabled = {groupPushDisabled()}
            />
            <Calendar scheduler={schedulerRef}
                      eventStore={eventStore}
                      dependencyStore={dependencyStore}
                      eventRecord={eventRecord}
                      setEventRecord={setEventRecord}
                      resourceRecord={resourceRecord}
                      setResourceRecord={setResourceRecord}
                      hideEditor={hideEditor}
                      popupShown={popupShown}
                      showPopup={showPopup}
                      showNoteEditor={showNoteEditor}
                      handleClickSave={handleClickSave}
                      handleClickSaveChildRelease={handleClickSaveChildRelease}
                      handleSelectGame={handleSelectGame}
                      setStatusForPushToAsanaEventContextMenu={setStatusForPushToAsanaEventContextMenu}
                      getBryntumConfigs={getBryntumConfigs}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
            />
            {user.selectedGame !== SELECTED_GAME.NO_GAME && <HelpButton/>}
        </Box>
    }
}
export default manageCalendar;
