import {FETCH_CALENDAR_CATEGORIES} from "../types";


const initialStateTimeConfig = []
export const categoriesReducer = (state = initialStateTimeConfig, action) => {
    switch (action.type) {
        case FETCH_CALENDAR_CATEGORIES:
            return  action.payload;
        default: return state
    }
};