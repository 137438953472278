import React, {useEffect, useState} from "react";

import './Popup.scss';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {DateHelper, ObjectHelper} from '@bryntum/scheduler/scheduler.umd';
import {sendRemoveReleaseEvent} from "../requests"
import TimeDropdown from "../elements/TimeDropdown";
import {EVENT_TYPE, MONGO_STATUS} from "../constants";
import CalendarDatePicker from "../elements/CalendarDatePicker";
import {hasText, isExists, isNotNull} from "../utils";
import {ColorPicker} from 'mui-color';
import {getEventColorFromConfigs, setTimeToDate} from "../calendars/bryntum_helpers";

export default function ReleaseEditPopup(props) {

    const [popupState, setPopupState] = useState({
        countries: props.eventRecord.data.countries === undefined ? [] : props.eventRecord.data.countries,
        ...props.eventRecord.data
    });
    const [release, setRelease] = useState({
        countries: [],
        duration: 1,
        type: "release",
        parentRelease: props.eventRecord.data.id,
        name: "",
        startDate: null,
        resourceId: popupState.resourceId,
        cls: props.eventRecord.cls,
        eventColor: isNotNull(getEventColorFromConfigs(props.eventRecord, props.resourceRecord)) ? getEventColorFromConfigs(props.eventRecord, props.resourceRecord) : undefined,
    });
    const [nestedReleaseEvents, addNestedReleaseEvents] = useState([]);

    useEffect(() => {
        if (props.eventRecord !== undefined) {
            let newNestedReleaseEvents = [];
            props.eventRecord.data.nestedIdEvents.forEach(id => {
                let nestedRelease = props.eventStore.getById(id);
                if (isExists(nestedRelease)) {
                    let startDate = nestedRelease.startDate
                    // https://medium.com/@stasonmars/%D0%BA%D0%BE%D0%BF%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82%D0%BE%D0%B2-%D0%B2-javascript-d25c261a7aff
                    //глубокое клонирование
                    const clonedNestedRelease = JSON.parse(JSON.stringify(nestedRelease.data))
                    clonedNestedRelease.startDate = startDate
                    newNestedReleaseEvents.push(clonedNestedRelease)
                }
            });

            addNestedReleaseEvents(newNestedReleaseEvents);
        }
    }, []);


    function startDateChangeHandler(startDate) {

        setPopupState((prevState) => ({
            ...prevState,
            duration: 1,
            startDate
        }));
    }


    function saveClickHandler() {
        function addDependency() {
            let filteredByIDReleaseEvents = nestedReleaseEvents.filter(item => item.id !== undefined || removeNestedEvents.includes(item.id));

            let trueAddedRecords = [];
            addedRecords.forEach(record => {
                record.data.startDate = record.data.startDate.toISOString();
                trueAddedRecords.push(record.data)
            })
            let allReleaseRecords = [...trueAddedRecords, ...filteredByIDReleaseEvents];

            allReleaseRecords.sort((first, second) => {
                let dateFirst = DateHelper.parse(first.startDate, 'YYYY-MM-DD');
                let dateSecond = DateHelper.parse(second.startDate, 'YYYY-MM-DD');
                return DateHelper.isBefore(dateFirst, dateSecond) ? -1 : 1;
            })
            if (allReleaseRecords.length > 0) {
                allReleaseRecords.forEach((record, index) => {

                    let eventDependencies = props.dependencyStore.getEventDependencies(record);
                    props.dependencyStore.remove(eventDependencies)

                    if (index === 0) {
                        props.dependencyStore.add({from: popupState.id, to: record.id})
                    } else {
                        props.dependencyStore.add({from: allReleaseRecords[index - 1].id, to: record.id})
                    }
                })
            }
        }

        function removeNestedRelease() {

            removeNestedEvents.forEach(id => sendRemoveReleaseEvent(id, EVENT_TYPE.RELEASE, props.game.toLowerCase()))
            props.eventStore.remove(removeNestedEvents);
        }

        function updateNestedRelease() {
            nestedReleaseEvents.forEach(item => {
                let oldNestedRelease = props.eventStore.getById(item.id);
                if (oldNestedRelease !== undefined) {
                    // JSON.stringify(oldNestedRelease.data) !== JSON.stringify(item)
                    if (!ObjectHelper.isEqual(oldNestedRelease.data, item)) {

                        oldNestedRelease.set({...item});
                        item.startDate = DateHelper.format(item.startDate, "YYYY-MM-DDTHH:mm:ss.SSSZ");
                        props.onSaveChild(item);
                    }
                }
            });
        }

        function createNestedEvents() {
            let createdNestedEvents = nestedReleaseEvents.filter(item => item.id === undefined);

            let addedRecords = [];
            if (createdNestedEvents.length > 0) {
                //add new nested events
                createdNestedEvents.forEach(nestedEvent => {
                    nestedEvent.startDate = DateHelper.parse(nestedEvent.startDate)
                    let seconds = Object.values(props.resourceRecord.defaultTime)[0];
                    let hours = seconds / 3600;
                    let minutes = (seconds % 3600) / 60;
                    DateHelper.set(nestedEvent.startDate, "h", hours);
                    DateHelper.set(nestedEvent.startDate, "m", minutes);

                })
                addedRecords = props.eventStore.add(createdNestedEvents);
                addedRecords.forEach(addedRecord => props.onSaveChild(addedRecord));
            }
            return addedRecords;
        }

        let addedRecords = createNestedEvents();
        let removeNestedEvents = popupState.nestedIdEvents.filter(nestedID => {
            const ids = nestedReleaseEvents.map(nestedReleaseEvent => nestedReleaseEvent.id)
            return !ids.includes(nestedID);
        });

        addDependency()
        removeNestedRelease();
        updateNestedRelease();
        if (!ObjectHelper.isEqual(popupState, props.eventRecord.data)) {
            props.onSave(popupState);
        } else {
            props.onClose();
        }
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 180,
            },
        },
    };
    const COUNTRIES = [
        'BR', 'PL', 'BY',
        'KZ', 'PH', 'ID',
        'TH', 'GR', 'MX',
        'CZ', 'UA', 'GB',
        'RU', 'DE'

    ];

    function handleChange(event) {
        setPopupState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    function handleChangeRelease(event) {
        setRelease((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    function addRelease() {
        let newNestedReleaseEvents = nestedReleaseEvents.concat(release);
        addNestedReleaseEvents(newNestedReleaseEvents);
    }

    function handleChangeExistRelease(event, index, name) {
        let value = event.target === undefined ? event : event.target.value
        let currentRelease = nestedReleaseEvents[index];
        currentRelease[name] = value;
        let currentReleases = [...nestedReleaseEvents];
        currentReleases[index] = currentRelease
        addNestedReleaseEvents(currentReleases);
    }


    function deleteRelease(index) {
        let nestedEvents = nestedReleaseEvents.filter((event, i) => index !== i);
        addNestedReleaseEvents(nestedEvents)
    }


    function isDisabledAddReleaseButton() {
        if (isExists(release.startDate)) {
            const diff = DateHelper.diff(new Date(release.startDate),
                new Date(popupState.startDate), "d", false);
            return (diff > 0 || !hasText(release.name))
        } else {
            return  !hasText(release.name)
        }
    }

    function saveButtonStatus() {
        if (popupState.name !== '') {
            return false
        }
        return true
    }

    function handleChangeReleaseStartDate(startDate) {

        setRelease((prevState) => ({
            ...prevState,
            startDate: DateHelper.format(startDate)
        }));
    }

    const changeStartTime = (time) => {

        let newStartDate = setTimeToDate(popupState.startDate.toString(), time)
        setPopupState((prevState) => ({
            ...prevState,
            useTimeInStartDate: true,
            startDate: newStartDate,
        }));
    }

    function changeColor(color) {
        const hexColor = "#" + color.hex
        setPopupState((prevState) => ({
            ...prevState,
            eventColor: hexColor
        }));
    }

    function getEventColor() {
        if (isExists(popupState.eventColor)) {
            return popupState.eventColor
        } else {
            return isNotNull(getEventColorFromConfigs(props.eventRecord, props.resourceRecord)) ? getEventColorFromConfigs(props.eventRecord, props.resourceRecord) : undefined;
        }
    }

    return (
        <div className='popup-mask'>
            <div className='popup'>
                <header>
                    <Link
                        href={props.eventRecord.link}
                        target="_blank"
                        rel="noopener noreferrer">
                        {popupState.name}
                    </Link>
                </header>
                <article>
                    <hr className="hr-text" data-content="Main Release" style={{marginTop: 10}}/>
                    <TextField
                        variant="standard"
                        name="name"
                        label="Event name"
                        onChange={(e) => handleChange(e)}
                        value={popupState.name}
                        disabled={popupState.mongoStatus === MONGO_STATUS.CREATED}
                        fullWidth
                        style={{marginBottom: 10}}/>
                    <Box sx={{my: "20px", width: "100%"}}>
                        <ColorPicker disableTextfield={true}
                                     value={getEventColor()}
                                     onChange={(event) => changeColor(event)}/>
                    </Box>
                    <CalendarDatePicker dateChangeHandler={startDateChangeHandler}
                                        label="Start release"
                                        styles={{"width": "84%", marginTop: "10px"}}
                                        date={popupState.startDate}/>
                    <TimeDropdown startDate={popupState.startDate} changeStartTime={changeStartTime}
                                  style={{margin: "27px 0 0 10px"}}/>
                    {props.eventRecord.data.nestedIdEvents !== undefined &&
                    <>
                        <hr className="hr-text" data-content="Nested Releases"/>
                        <section style={{marginBottom: 10, paddingBottom: 0}}>
                            <TextField id="standard-basic1" type="number" label="%" name="name"
                                       variant="standard"
                                       onChange={(e) => handleChangeRelease(e)}
                                       style={{width: '15%', marginRight: 5}}/>
                            <CalendarDatePicker dateChangeHandler={handleChangeReleaseStartDate}
                                                label="Start release"
                                                styles={{
                                                    "width": "33%",
                                                    marginRight: 2,
                                                    marginTop: 0,
                                                    verticalAlign: 'middle'
                                                }}
                                                date={release.startDate}/>
                            <FormControl style={{width: '35%', marginRight: 5}}>
                                <InputLabel id="demo-mutiple-name-label">Country</InputLabel>
                                <Select
                                    labelid="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    multiple
                                    value={release.countries}
                                    variant="standard"
                                    name="countries"
                                    onChange={(event) => handleChangeRelease(event)}
                                    input={<Input/>}
                                    MenuProps={MenuProps}>
                                    {COUNTRIES.map((country) => (
                                        <MenuItem key={country} value={country}>
                                            {country}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button variant="contained" color="primary" style={{display: "inline-flex"}}
                                    disabled={isDisabledAddReleaseButton()}
                                    onClick={() => addRelease()}>Add</Button>
                        </section>
                        <section className="release-list" style={{marginBottom: 10, marginTop: 10, paddingBottom: 0}}>
                            {nestedReleaseEvents.map((nestedEvent, index) => (
                                <div key={index} style={{marginBottom: 10, marginTop: 15}}>
                                    <TextField id="standard-basic1" type="number" label="%" name="name"
                                               value={nestedEvent.name}
                                               variant="standard"
                                               onChange={(e) => handleChangeExistRelease(e, index, "name")}
                                               style={{width: '15%', marginRight: 5}}/>
                                    <CalendarDatePicker
                                        dateChangeHandler={(e) => handleChangeExistRelease(e, index, "startDate")}
                                        label="Start release"
                                        styles={{"width": "34%", marginRight: 2, marginTop: 0, verticalAlign: 'middle'}}
                                        date={nestedEvent.startDate}/>
                                    <FormControl style={{width: '35%', marginRight: 5}}>
                                        <InputLabel id="demo-mutiple-name-label">Country</InputLabel>
                                        <Select
                                            labelid="demo-mutiple-name-label"
                                            id="demo-mutiple-name"
                                            multiple
                                            variant="standard"
                                            value={nestedEvent.countries}
                                            name="countries"
                                            onChange={(event) => handleChangeExistRelease(event, index, "countries")}
                                            input={<Input/>}
                                            MenuProps={MenuProps}>
                                            {COUNTRIES.map((country) => (
                                                <MenuItem key={country} value={country}>
                                                    {country}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <IconButton aria-label="delete" onClick={() => deleteRelease(index)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </div>
                            ))}
                        </section>
                    </>}
                </article>

                <footer>
                    <Button variant="contained" color="primary" disabled={saveButtonStatus()}
                            onClick={() => saveClickHandler()}>Save</Button>
                    <Button variant="contained" color="secondary" onClick={() => props.closePopup()}>Cancel</Button>
                </footer>
            </div>
        </div>
    )
}
