import {FETCH_CALENDAR_CATEGORIES} from "../types";

export function fetchCalendarCategories(game) {
    return async  dispatch => {

        try {
            const response = await fetch(`/calendar-categories/`+game, {method: 'GET'});
            const body = await response.text();
            let payload = null;
            if (body !== '') {
                payload = JSON.parse(body);

            }
            dispatch({type: FETCH_CALENDAR_CATEGORIES, payload})
        } catch (e) {
            dispatch({type: FETCH_CALENDAR_CATEGORIES, payload: {}})
        }
    }
}