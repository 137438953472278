import {SYNC_INFO, SYNC_PROGRESS} from "./actions";

const initialState = {
    asanaStatus: "SYNCED",
    syncAsanaTasksProgress: []
};
export const asanaSyncInfoReducer =(state = initialState, action) => {
    switch (action.type) {
        case SYNC_INFO:
            return { ...state, ...action.payload};
        case SYNC_PROGRESS:
            return { ...state, syncAsanaTasksProgress: action.payload};
        default: return state
    }
};