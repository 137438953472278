import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

export function SyncPopup(props) {

    return <Dialog
        open={props.isOpenSyncPopup}
        onClose={() => props.handleSyncPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title" className="sync_popup__blue_gradient"
                     sx={{textAlign: "center", color: "white"}}>Синхронизация</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{textAlign: "center", pt: 3}}>
                {props.message}
            </DialogContentText>

        </DialogContent>
        <DialogActions sx={{justifyContent: "space-around"}}>
            <Button onClick={() => props.handleSyncPopup(false)} className="sync_popup__red_gradient"
                    sx={{color: "white"}}>Отмена</Button>
            <Button onClick={props.syncEvents} className="sync_popup__blue_gradient"
                    sx={{color: "white"}}>OK</Button>

        </DialogActions>
    </Dialog>
}