import React from "react"
import { Route } from "react-router-dom"
import { SentryRoutes } from "../index"
import LoginForm from "../LoginForm"

const UnauthenticatedRouting = () => (
    <SentryRoutes>
        <Route exact path="*" element={<LoginForm />} />
    </SentryRoutes>
)

export default UnauthenticatedRouting
