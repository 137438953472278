import { EventStore, ResourceStore } from "@bryntum/scheduler/scheduler.umd.js"
import BryntumEvent from "../BryntumEvent"
import { EVENT_TYPE, SELECTED_GAME } from "../constants"
import { sendAddEvent, sendRemoveEvent } from "../requests"
import { isBoolean, isExists, isInstanceOf } from "../utils"

const handleOfferEventChange = (resourceStore, eventStore, event, checked) => {
    if (!isInstanceOf(resourceStore, ResourceStore)) return
    if (!isInstanceOf(eventStore, EventStore)) return
    if (!isInstanceOf(event, BryntumEvent)) return
    if (!isBoolean(checked)) return

    event.set("offer", checked)

    if (checked) {
        const resource = resourceStore.findRecord("type", "offer")

        if (!isExists(resource)) return

        const eventCopy = event.copy({
            id: `${event.id}_offer`,
            resourceId: resource.id,
            type: EVENT_TYPE.OFFER,
            resizable: false
        })

        const addedEvents = eventStore.add(eventCopy)

        if (addedEvents.length !== 1) return

        sendAddEvent(addedEvents[0], SELECTED_GAME.SC.toLowerCase())
    } else {
        const removedEvents = eventStore.remove(`${event.id}_offer`)

        if (removedEvents.length !== 1) return

        sendRemoveEvent(removedEvents[0], SELECTED_GAME.SC.toLowerCase())
    }
}

export default handleOfferEventChange
