import "@bryntum/demo-resources/scss/example.scss"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    Routes,
    useLocation,
    useNavigationType
} from "react-router-dom"
import App from "./App.js"
import store from "./store"
import {isDevelopment, isLocal} from "./utils"

Sentry.init({
    enabled: !isLocal(),
    dsn: "https://2d465d507a6148dfa74b6d4c29ba48e8@cross-sentry.belka-games.com/6",
    environment: !isDevelopment() ? "production" : "development",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            )
        }),
        new Sentry.Integrations.GlobalHandlers({
            onerror: true,
            onunhandledrejection: true
        })
    ],
    tracesSampleRate: 1.0
})

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
console.log('#build#1')

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Sentry.ErrorBoundary fallback={<p>An error occurred</p>}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Sentry.ErrorBoundary>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
)