import React from 'react'
import {Autocomplete} from "@mui/lab";
import {TextField} from "@mui/material";
export default function LabelSelector(props) {
    function test(v) {
        debugger
    }
    return (
        <Autocomplete
            value={props.popupState?.label?.name ? props.popupState.label.name : ""}
            onChange={(e, v) => props.changeLabel(v, props.labels[v])}
            renderInput={params => (
                <TextField
                    {...params}
                    id="label"
                    variant="standard"
                    label="Tag"
                />
            )}
            options={Object.keys(props.labels)}>

        </Autocomplete>
    )
}