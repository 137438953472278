import {EVENT_TYPE} from "../../constants";
import {getEventName} from "../../utils";

export const labelsFeature = {
    labelLayoutMode: 'measure',

    top: {
        renderer: ({eventRecord, domConfig}) => {


            if (eventRecord.duration !== 1) {
                let className = "b-sch-label-none";
                domConfig.className[className] = true;
            } else if (eventRecord.parentRelease !== undefined) {

                return eventRecord.name + "%"
            }
        }
    },
    bottom: {
        renderer: ({eventRecord, domConfig}) => {
            let labelData = _getLabelData(eventRecord)
            // <i className="fas fa-dot-circle"></i>
            if (labelData) {
                domConfig.className[labelData.labelClass] = true;
                return labelData.labelContent;
            }
            let className = "b-sch-label-none";
            domConfig.className[className] = true;
        }
    },
    right: {
        renderer({domConfig, eventRecord}) {
            if (eventRecord.duration !== 1 || eventRecord.type === EVENT_TYPE.RELEASE) {
                let className = "b-sch-label-none";
                domConfig.className[className] = true;
            }
        },
    },
    left: {
        renderer({domConfig, eventRecord}) {
            const getClassByMongoStatus =() =>  {
                switch (eventRecord.mongoStatus) {
                    case "CREATED":
                        return "b-fa b-fa-plus"
                    case "UPDATED":
                        return "b-fa b-fa-pen"
                    default:
                        return ""
                }
            }
            if (eventRecord.duration === 1) {
                if (eventRecord.parentRelease !== undefined) {
                    let className = "b-sch-label-none";
                    domConfig.className[className] = true;
                } else if (eventRecord.nestedIdEvents!==undefined){
                    let className = "b-sch-label-left-release-parent";
                    domConfig.className[className] = true
                }
                return `<div>${getEventName(eventRecord)}&nbsp<div class="${getClassByMongoStatus()}"></div></div>`

            } else {
                let className = "b-sch-label-none";
                domConfig.className[className] = true;
            }
        }
    }
}

function _getLabelData(eventRecord) {
    if (eventRecord?.data?.hasOwnProperty('label')) {
        let color
        let content;
        let labelClass;
        color = eventRecord.data.label.color;
        content = eventRecord?.data?.duration > 1 ? eventRecord.data.label.name : null;
        labelClass = eventRecord?.data?.duration > 1 ? `custom-label ${color}` : `custom-label dot ${color}`;
    return {labelClass : labelClass, labelContent : content}
    }
}
