import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    createTheme,
    Divider, IconButton,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    SwipeableDrawer,
    ThemeProvider,
    Typography
} from "@mui/material";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import {isEmptyArray, isExists} from "../utils";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import {indigo} from "@mui/material/colors";
import {DateHelper} from '@bryntum/scheduler/scheduler.umd';
import {COMPLETED_SYNC_STATUS} from "../constants";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export function SyncTasksStatus() {

    const [isOpen, setIsOpen] = React.useState(false);

    const tasksProgress = useSelector((state) => state.asanaSyncInfo.syncAsanaTasksProgress)

    const themeStatusButton = createTheme({
        palette: {
            primary: {
                main: indigo[900],
            }
        },
    });
    const Demo = styled('div')(({theme}) => ({
        backgroundColor: theme.palette.background.paper,
    }));

    const toggleDrawer = (open) => (event) => {
        setIsOpen(open)
    };

    function getIconProgressByStatus(progress) {
        let componentProgress;
        switch (progress.status?.completedSyncStatus) {
            case (COMPLETED_SYNC_STATUS.COMPLETED): {
                componentProgress = <DoneIcon color="success"/>
                break;
            }
            case (COMPLETED_SYNC_STATUS.IN_PROGRESS): {
                componentProgress = <CircularProgress color="inherit"/>
                break;
            }
            case (COMPLETED_SYNC_STATUS.FAILED): {
                componentProgress = <ErrorIcon color="error"/>
                break;
            }
            default:
                break
        }
        return componentProgress;
    }

    return <>
        <ThemeProvider theme={themeStatusButton}>
            <Button onClick={() => setIsOpen(true)} variant="contained">Status</Button>
        </ThemeProvider>
        <SwipeableDrawer
            anchor="right"
            open={isOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}>

            <Box
                sx={{width: 300}}
                role="presentation"
            >
                <Box sx={{height: "64px", display: "inline-flex"}}>
                    <IconButton aria-label="close" onClick={toggleDrawer(false)} sx={{height: "fit-content"}}>
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" sx={{marginTop: "16px", marginLeft: "32px"}}>Sync Tasks Status</Typography>

                </Box>

                <Divider/>
                <Demo>{isEmptyArray(tasksProgress) ?
                    <Typography sx={{m: "20px 20px 10px 20px", opacity: "0.5"}}>No sync with asana in last 12
                        hours</Typography> : <List dense={true}>
                        {tasksProgress.map((progress, index) => {
                                return <ListItem button component={Link} href={progress.task.asanaLink} target="_blank"
                                                 key={index}>
                                    <ListItemAvatar>{getIconProgressByStatus(progress)}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<Box sx={{display: "flex", justifyContent: "start"}}>
                                            {progress.direction === "TO_ASANA"?
                                                <Box className="sync_tasks_status__from_calendar_status"/>:
                                                <Box className="sync_tasks_status__from_asana_status"/>
                                            }

                                            <Box
                                                sx={{paddingTop: "5px", maxWidth: "180px"}}>{progress.task.mongoStatus + ": " + progress.task.name}</Box>
                                        </Box>}
                                        secondary={<>
                                            {progress.status.message && <div>{progress.status.message}</div>}
                                            <span>{progress.task.syncLastDate && DateHelper.format(new Date(progress.task.syncLastDate), "Y.MM.DD HH:mm:ss")}</span>
                                            <div>{progress.syncUser && progress.syncUser.substring(0, progress.syncUser.indexOf("@"))}</div>
                                        </>}
                                    />
                                </ListItem>

                            }
                        )}
                    </List>}

                </Demo>

            </Box>
        </SwipeableDrawer></>
}