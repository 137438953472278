export const ADD_SYNC_EVENT = "ADD_SYNC_EVENT"
export const REMOVE_SYNC_EVENT = "REMOVE_SYNC_EVENT"

const addSyncEvent = (eventId) => ({
    type: ADD_SYNC_EVENT,
    payload: eventId
})

const removeSyncEvent = (eventId) => ({
    type: REMOVE_SYNC_EVENT,
    payload: eventId
})

export const addSyncEventAction = (eventId) => async (dispatch) => {
    dispatch(addSyncEvent(eventId))
}

export const removeSyncEventAction = (eventId) => async (dispatch) => {
    dispatch(removeSyncEvent(eventId))
}
