import React, {useEffect, useMemo, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import {format} from "date-fns";
import {getDateInServerTimeZone} from "../utils";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import {getAsanaProjectStatusByVersion, getEventLogs} from "../requests";

const columns = [
    {field: '_id', headerName: 'id', hide: true},
    {
        field: 'lastModified', headerName: 'Дата изменения', width: 150,
        renderCell: (params) =>
            <div>{format(getDateInServerTimeZone(params.row.lastModified), "dd.MM.yyyy HH:mm")}</div>
    },
    {field: 'eventName', headerName: 'Имя события',  width: 200},
    {field: 'eventType', headerName: 'Тип',  width: 70},
    {field: 'mongoStatus', headerName: 'Статус',  width: 130},
    {field: 'modificationAuthorName', headerName: 'Автор',  width: 170},
    {field: 'asanaLink', headerName: 'Asana', width: 100,
        renderCell: (params) => {
            //return <a href={params.row.asanaLink}>link</a>
            return params.row.asanaLink ?
                <a href={params.row.asanaLink}>link</a> :
                <p>n/a</p>
        },
    },

];

export function LogsDialog(props) {

    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("in logs dialog " + props.game)
        getEventLogs(props.game)
            .then((data) => {
                setLogs(data.logs);
            })
            .catch((error) => {
                console.error("Error fetching event logs:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.game]);

    return <Dialog
        fullWidth
        maxWidth={'md'}
        open={props.isOpen}
        onClose={() => props.onClose(false)}
    >
        <DialogTitle id="alert-dialog-title" className="sync_popup__blue_gradient"
                     sx={{textAlign: "center", color: "white"}}>Журнал действий</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{textAlign: "center", pt: 3}}>
                {loading ? <CircularProgress/>
                    : <Box sx={{width: '100%', height: 600}}>
                    <DataGrid
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        sx={{m: 2}}
                        rows={logs}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                       // getRowId={(row) => row._id.$oid || row._id} // Use _id as the row ID

                    />
                </Box>
                }
            </DialogContentText>

        </DialogContent>
        <DialogActions sx={{justifyContent: "space-around"}}>
            <Button onClick={() => props.onClose()} className="sync_popup__red_gradient"
                    sx={{color: "white"}}>Закрыть</Button>

        </DialogActions>
    </Dialog>
}