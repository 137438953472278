import {hasText} from "../../utils";
import {DateHelper} from "@bryntum/scheduler/scheduler.umd";
import {hideEventTimeRange, processTimeAxisHeaderClick, setEventTimeRange} from "../bryntum_helpers";
import {eventClick} from "./eventClick";

export const getViewerConfigs = (scheduler) => {
    return {
        createEventOnDblClick: false,
        eventMenuFeature: {
            items: {
                eventLink: {
                    text: 'Link to Asana',
                    icon: "b-fa b-fa-fw b-fa-external-link-alt",
                    onItem({eventRecord}) {
                        if (hasText(eventRecord.link)) {
                            window.open(eventRecord.link, "_blank")
                        }
                    }
                },
                cutEvent: false,
                copyEvent: false,
                pasteEvent: false

            },
            processItems({eventRecord, items}) {
                items.editEvent.hidden = true;
                items.deleteEvent.hidden = true;
            }
        },
        scheduleMenuFeature: {
            processItems({items}) {
                items.addEvent.hidden = true;
            }
        },
        listeners: {
            beforeEventEdit: source => {
                return false;
            },
            timeAxisHeaderClick: (event) => {
                processTimeAxisHeaderClick(scheduler, event);
            },
            eventClick: ({event, eventRecord}) => {
                setEventTimeRange(scheduler, eventRecord);
                return eventClick(event, eventRecord)
            },
            scheduleClick: () => {
                hideEventTimeRange(scheduler);
            }
        },
        eventResizeFeature: false,
        eventDragFeature: false,
    }
}