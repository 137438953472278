import * as Sentry from "@sentry/react"
import { isExists } from "../utils"
import { FETCH_USER } from "./types"

export const fetchUser = () => async (dispatch) => {
    try {
        const response = await fetch("/api/user", { method: "GET" })
        const user = await response.json()

        if (isExists(user.email)) {
            Sentry.setUser({ email: user.email })
        }

        dispatch({ type: FETCH_USER, payload: user })
    } catch (e) {
        console.error("FETCH USER ERROR")
        dispatch({ type: FETCH_USER, payload: null })
    }
}

export const logoutUser = () => async () => {
    await fetch("/api/user/logout", { method: "POST" })
}
