import { ADD_SYNC_EVENT, REMOVE_SYNC_EVENT } from "./actions"

const initialState = new Set()

const syncEventsReducer = (state = initialState, action) => {
    const _state = new Set(state)

    switch (action.type) {
        case ADD_SYNC_EVENT:
            _state.add(action.payload)

            return _state
        case REMOVE_SYNC_EVENT:
            _state.delete(action.payload)

            return _state
        default:
            return state
    }
}

export default syncEventsReducer
