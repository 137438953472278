import { EventModel } from "@bryntum/scheduler/scheduler.umd"
import { v4 as UUIDv4 } from "uuid"

export default class BryntumEvent extends EventModel {
    static get fields() {
        return [
            "colorParams",
            "gid",
            "countries",
            "eventId",
            "isArchive",
            "levelsBR",
            "levelsPL",
            "levelsWW",
            "link",
            { name: "name", dataSource: "name", defaultValue: "" },
            { name: "nestedIdEvents", dataSource: "nestedIdEvents", alwaysWrite: true, defaultValue: []},
            "note",
            "parentRelease",
            "persent",
            "template",
            "type",
            "offer",
            "promo",
            "label",
            "featuring",
            "push",
            "eventsOffers",
            "version",
            "teamProject",
            "mongoStatus",
            "isCopied",
            "copiedTime",
            "originalID",
            "isSyncFromAsana",
            "isSyncToAsana",
            "useTimeInStartDate",
            "useTimeInEndDate",
            "isParentCompositeEvent",
            "parentCompositeEventId",
            "autorun",
            "devbuild"
        ]
    }

    generateId() {
        return `_generated_${UUIDv4()}`
    }
}
