import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {hasText, isEmptyArray, isExists, setFeaturingType, setHoursForEventDates} from "../utils";
import {
    BA_EVENTS_OFFERS_NAME,
    COMMUNITY_TEMPLATES,
    EVENT_TYPE, getEventSubType,
    SELECTED_GAME
} from "../constants";
import {pushEventChangesInAsana, sendAddEvent, sendRemoveEvent, sendUpdateEvent} from "../requests";
import {DateHelper, MessageDialog, ObjectHelper, StringHelper} from "@bryntum/scheduler/scheduler.umd";
import {BryntumScheduler} from "@bryntum/scheduler-react";
import Popup from "../elements/Popup";
import EditNotePopup from "../components/EditNotePopup";
import ReleaseCreatePopup from "../components/ReleaseCreatePopup";
import ReleaseEditPopup from "../components/ReleaseEditPopup";
import manageCalendar from "./manageCalendar";
import "./ba_styles.css"
import {eventClick} from "./bryntum_configs/eventClick";
import {
    clearCopiedFieldForEvents, getLiveOpsTemplateInfoNameIfExist, hideEventTimeRange, processTimeAxisHeaderClick, setEventTimeRange
} from "./bryntum_helpers";
import store from "../store";
import DeletePopup from "../components/DeletePopup";
import {fetchLabelsConfig} from "../redux/labels-config/actions";
import {fetchCalendarCategories} from "../redux/categories/actions";


let startDate = new Date();
startDate.setDate(startDate.getDate() - 60);
let endDate = new Date();
endDate.setDate(endDate.getDate() + 365);


const RELEASES = {
    "DEV версия": ['BC апрув', 'RC апрув', 'Feature freeze'],
    "Android Сабмит/Релиз": ['Release: Android', 'Submit: Android'],
    "iOS Сабмит/Релиз": ['Release: iOS', 'Submit: iOS'],
    "AM Сабмит/Релиз": ['Release: Amazon', 'Submit: Amazon'],
    "WS Сабмит/Релиз": ["Submit: WS", "Release: WS"],
    "Hard/Soft Update": ['Soft Update после релиза', 'Hard Update после релиза']
};

const BACalendar = (props) => {

    const user = useSelector((state) => state.user.currentUser);
    const labelsConfig = useSelector((state) => state.labelsConfig);
    let [existsInAsana, setExistsInAsana] = useState(false);
    const [deleteRecords, setDeleteRecords] = useState([]);

    let timeInStartDate = 0;

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchLabelsConfig("ba"))
        dispatch(fetchCalendarCategories("ba"))

    }, [])

    const getEditorConfigs = () => {
        return {
            multiEventSelect: true,
            eventDragFeature: {
                constrainDragToResource: true
            },
            rowCopyPasteFeature: false,
            eventResizeFeature: true,
            eventMenuFeature: {
                items: {
                    editEventNote: {
                        text: 'Edit event note',
                        icon: "b-fa b-fa-fw b-fa-sticky-note",
                        onItem({eventRecord}) {
                            props.showNoteEditor(eventRecord)
                        }
                    },
                    eventLink: {
                        text: 'Link to Asana',
                        icon: "b-fa b-fa-fw b-fa-external-link-alt",
                        weight: 0,
                        onItem({eventRecord}) {

                            if (hasText(eventRecord.link)) {
                                window.open(eventRecord.link, "_blank")
                            }
                        }
                    },
                    pushToAsana: {
                        text: 'Push to Asana',
                        icon: "b-fa b-fa-fw b-fa-angle-right",
                        onItem({eventRecord}) {
                            pushEventChangesInAsana(eventRecord, SELECTED_GAME.BA.toLowerCase())
                        }
                    },
                    copyLink: {
                        text: 'Copy link',
                        icon: "b-fa b-fa-fw b-icon-copy",
                        weight: 0,
                        onItem({eventRecord}) {
                            if (hasText(eventRecord.link)) {
                                navigator.clipboard.writeText(eventRecord.link).then();
                            }
                        }
                    },
                    manageFeaturing: {
                        text: 'Add to featuring',
                        icon: "b-fa b-fa-fw b-icon-copy",
                        onItem(item) {
                            let clonedEvent = item.eventRecord.data;
                            clonedEvent.addFeaturing = true;
                            // clonedEvent.startDate = clonedEvent.startDate.toString()
                            sendUpdateEvent(clonedEvent, SELECTED_GAME.BA.toLowerCase());
                        }
                    },
                    managePromo: {
                        text: 'Add to promo',
                        type: "checkbox",
                        checked: false,
                        icon: "b-fa b-fa-fw",
                        onAction(item) {
                            if (item.checked) {
                                let currentRecord = item.source.eventRecord;
                                let promoResource = props.scheduler.current.schedulerInstance.resourceStore.findRecord("name", "LiveOps Promo");
                                let clonedCurrentRecord = ObjectHelper.clone(currentRecord.data);
                                clonedCurrentRecord.id = clonedCurrentRecord.id + "_promo";
                                clonedCurrentRecord.resourceId = promoResource.id;
                                clonedCurrentRecord.type = EVENT_TYPE.PROMO;
                                clonedCurrentRecord.resizable = false;
                                props.scheduler.current.schedulerInstance.eventStore.addAsync(clonedCurrentRecord);
                                currentRecord.setAsync("promo", true)
                                sendAddEvent(clonedCurrentRecord, SELECTED_GAME.BA.toLowerCase());


                            } else {
                                let currentRecord = item.source.eventRecord;
                                let promoRecord = props.scheduler.current.schedulerInstance.eventStore.findRecord("id", currentRecord.id + "_promo");
                                currentRecord.setAsync("promo", false);

                                props.scheduler.current.schedulerInstance.eventStore.remove(promoRecord.id);
                                let clonedCurrentRecord = ObjectHelper.clone(promoRecord);
                                clonedCurrentRecord.id = currentRecord.id;
                                sendRemoveEvent(promoRecord, SELECTED_GAME.BA.toLowerCase())
                            }
                        }
                    },
                    managePush: {
                        text: 'Add to push notifications',
                        type: "checkbox",
                        checked: false,
                        icon: "b-fa b-fa-fw",
                        onAction(item) {
                            if (item.checked) {
                                let currentRecord = item.source.eventRecord;
                                let pushResource = props.scheduler.current.schedulerInstance.resourceStore.findRecord("name", "Push notifications");
                                let clonedCurrentRecord = ObjectHelper.clone(currentRecord.data);

                                clonedCurrentRecord.id = clonedCurrentRecord.id + "_push";
                                clonedCurrentRecord.resourceId = pushResource.id;
                                clonedCurrentRecord.type = EVENT_TYPE.PUSH;
                                clonedCurrentRecord.endDate = clonedCurrentRecord.startDate;
                                clonedCurrentRecord.duration = 1;
                                clonedCurrentRecord.resizable = false;


                                clonedCurrentRecord.endDate = DateHelper.add(clonedCurrentRecord.startDate, 1, 'd')


                                props.scheduler.current.schedulerInstance.eventStore.addAsync(clonedCurrentRecord);
                                currentRecord.setAsync("push", true)
                                sendAddEvent(clonedCurrentRecord, SELECTED_GAME.BA.toLowerCase());

                            } else {
                                let currentRecord = item.source.eventRecord;
                                let pushRecord = props.scheduler.current.schedulerInstance.eventStore.findRecord("id", currentRecord.id + "_push");
                                currentRecord.setAsync("push", false);

                                props.scheduler.current.schedulerInstance.eventStore.remove(pushRecord.id);
                                let clonedCurrentRecord = ObjectHelper.clone(pushRecord);
                                clonedCurrentRecord.id = currentRecord.id;
                                sendRemoveEvent(pushRecord, SELECTED_GAME.BA.toLowerCase())
                            }
                        }
                    },
                    manageEventsOffers: {
                        text: 'Add to Events Offers',
                        type: "checkbox",
                        checked: false,
                        icon: "b-fa b-fa-fw",
                        onAction(item) {
                            if (item.checked) {
                                let currentRecord = item.source.eventRecord;
                                let eventOfferResource = props.scheduler.current.schedulerInstance.resourceStore.findRecord("name", "Event Offers");
                                const eventSubtype = getEventSubType(currentRecord, getEventTemplates());
                                const eventOfferConfig = BA_EVENTS_OFFERS_NAME[eventSubtype];
                                if (eventOfferConfig) {
                                    eventOfferConfig.forEach((config, index) => {
                                        let clonedCurrentRecord = ObjectHelper.clone(currentRecord.data);
                                        clonedCurrentRecord.id = clonedCurrentRecord.id + "_eventOffers" + index;
                                        clonedCurrentRecord.name = config.name;
                                        clonedCurrentRecord.note = currentRecord.name;
                                        clonedCurrentRecord.template = null;
                                        if (config.durationDays !== null) {
                                            clonedCurrentRecord.duration = 2;
                                            const dateOffset = (24 * 60 * 60 * 1000) * config.durationDays;
                                            const duration = (24 * 60 * 60 * 1000) * clonedCurrentRecord.duration;
                                            if (config.isInTheEndOfMainEvent) {
                                                clonedCurrentRecord.startDate.setTime(currentRecord.endDate.getTime() - dateOffset);
                                                clonedCurrentRecord.endDate.setTime(clonedCurrentRecord.startDate.getTime() + duration);
                                            } else {
                                                clonedCurrentRecord.startDate.setTime(currentRecord.startDate.getTime() + dateOffset);
                                                clonedCurrentRecord.endDate.setTime(clonedCurrentRecord.startDate + duration);
                                            }
                                        }
                                        clonedCurrentRecord.resourceId = eventOfferResource.id;
                                        clonedCurrentRecord.type = EVENT_TYPE.EVENT;
                                        clonedCurrentRecord.resizable = true;
                                        props.scheduler.current.schedulerInstance.eventStore.addAsync(clonedCurrentRecord);
                                        currentRecord.setAsync("eventsOffers", true)
                                        sendAddEvent(clonedCurrentRecord, SELECTED_GAME.BA.toLowerCase());
                                    })
                                }

                            } else {
                                let currentRecord = item.source.eventRecord;
                                const eventSubtype = getEventSubType(currentRecord, getEventTemplates());
                                const eventOfferConfig = BA_EVENTS_OFFERS_NAME[eventSubtype];
                                if (eventOfferConfig) {
                                    eventOfferConfig.forEach(config => {
                                        let promoRecord = props.scheduler.current.schedulerInstance.eventStore.findRecord("note", currentRecord.name);
                                        currentRecord.setAsync("eventsOffers", false);
                                        props.scheduler.current.schedulerInstance.eventStore.remove(promoRecord.id);
                                        sendRemoveEvent(promoRecord, SELECTED_GAME.BA.toLowerCase())
                                    })
                                }
                            }
                        }
                    },
                    cutEvent: false
                },
                processItems({eventRecord, items}) {
                    items.managePromo.eventRecord = eventRecord
                    items.managePush.eventRecord = eventRecord
                    items.manageEventsOffers.eventRecord = eventRecord
                    items.manageFeaturing.eventRecord = eventRecord

                    function checkPromoRecord() {
                        if (eventRecord.promo) {
                            items.managePromo.checked = true;
                        }
                    }
                    function checkPushRecord() {
                        if (eventRecord.push) {
                            items.managePush.checked = true;
                        }
                    }
                    function checkEventsOffersRecord() {
                        if (eventRecord.eventsOffers) {
                            items.manageEventsOffers.checked = true;
                        }
                    }
                    function checkFeaturingRecord() {
                        if (eventRecord && eventRecord.featuring) {
                            items.manageFeaturing.checked = true;
                        }
                    }

                    checkPromoRecord();
                    checkPushRecord();
                    checkEventsOffersRecord();
                    // checkFeaturingRecord();

                    if (eventRecord.isArchive) {
                        items.editEventNote.disabled = true;
                        items.editEvent.disabled = true;
                        items.deleteEvent.disabled = true;
                        if (items.hasOwnProperty('hideEvent')) {
                            items.hideEvent.disabled = true;
                        }
                        items.copyEvent.disabled = true;
                        items.managePromo.disabled = true;
                        items.manageFeaturing.disabled = true;
                        items.managePush.disabled = true;
                    } else if (eventRecord.type === EVENT_TYPE.HOLIDAY) {
                        items.editEventNote.hidden = true;
                        items.managePromo.hidden = true;
                        items.managePush.hidden = true;
                        // items.manageFeaturing.hidden = true;
                        items.manageEventsOffers.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.RELEASE) {
                        items.copyEvent.hidden = true;
                        items.managePromo.hidden = true;
                        items.managePush.hidden = true;
                        items.manageFeaturing.hidden = true;
                        if (isExists(eventRecord.parentRelease)) {
                            items.managePromo.hidden = true;
                            items.managePush.hidden = true;
                        }
                    } else if (eventRecord.type === EVENT_TYPE.ASO) {
                        items.copyEvent.hidden = true;
                        items.managePromo.hidden = true;
                        items.managePush.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.deleteEvent.hidden = false
                    } else if (eventRecord.type === EVENT_TYPE.PROMO) {
                        items.editEventNote.hidden = true;
                        items.editEvent.hidden = true;
                        items.deleteEvent.hidden = true;
                        items.copyEvent.hidden = true;
                        items.managePromo.hidden = true;
                        items.managePush.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.manageEventsOffers.hidden = true;
                    } else if (eventRecord.type ===EVENT_TYPE.EVENT && window.atob(eventRecord.resourceId) === 'Featuring') {
                        items.managePromo.hidden = true;
                        items.managePush.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.editEventNote.hidden = true;
                        // items.editEvent.hidden = true;
                        // items.deleteEvent.hidden = true;
                        items.copyEvent.hidden = true;
                        items.pushToAsana.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.PUSH) {
                        items.editEventNote.hidden = true;
                        items.editEvent.hidden = true;
                        items.deleteEvent.hidden = true;
                        if (items.hasOwnProperty('hideEvent')) {
                            items.hideEvent.disabled = true;
                        }
                        items.copyEvent.hidden = true;
                        items.managePromo.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.managePush.hidden = true;
                        items.manageEventsOffers.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.COMMUNITY) {
                        items.managePromo.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.managePush.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.FEATURING) {
                        items.managePromo.hidden = true;
                        items.managePush.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.editEventNote.hidden = true;
                        // items.editEvent.hidden = true;
                        // items.deleteEvent.hidden = true;
                        items.copyEvent.hidden = true;
                        items.pushToAsana.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.BRANDFORMANCE) {
                        items.managePromo.hidden = true;
                        items.managePush.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.editEventNote.hidden = true;
                        items.copyEvent.hidden = true;
                        items.pushToAsana.hidden = true;
                        items.copyLink.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.IMPORTANT_DATES) {
                        items.managePromo.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.managePush.hidden = true;
                    }

                    if (getEventSubType(eventRecord, getEventTemplates()) === "") {
                        items.manageEventsOffers.hidden = true;
                    }
                    if (!isExists(eventRecord.link)) {
                        items.eventLink.disabled = true;
                    }
                    props.setStatusForPushToAsanaEventContextMenu(eventRecord, items)
                }
            },
            listeners: {
                beforeEventDelete: (source) => processBeforeEventDelete(source),
                beforeEventEdit: (source) => {
                    debugger
                    source.eventRecord.resourceId = source.resourceRecord.id;
                    let eventRecord = source.eventRecord;
                    let resourceRecord = source.resourceRecord;
                    if (isExists(source.eventRecord.parentRelease)) {
                        eventRecord = props.scheduler.current.schedulerInstance.eventStore.getById(source.eventRecord.parentRelease);
                    }
                    if (!eventRecord.isArchive  &&
                        resourceRecord.type !== EVENT_TYPE.PROMO && resourceRecord.type !== EVENT_TYPE.PUSH) {
                        showEditor(eventRecord, resourceRecord);
                    }

                    return false;
                },
                beforeEventDrag: ({eventRecord}) => {
                    if (!eventRecord.isArchive) {
                        if (eventRecord.type !== EVENT_TYPE.PROMO
                        && eventRecord.type !== EVENT_TYPE.PUSH
                        ) {
                            return true
                        }
                    }
                    return false
                },
                beforeEventResize: ({eventRecord}) => {
                    timeInStartDate = eventRecord.data.startDate.getHours();
                    if (!eventRecord.isArchive) {
                        if (eventRecord.type === EVENT_TYPE.EVENT
                            || eventRecord.type === EVENT_TYPE.AB_TEST
                            || eventRecord.type === EVENT_TYPE.FEATURING
                            || eventRecord.type === EVENT_TYPE.BRANDFORMANCE) {
                            return true
                        }
                    }
                    return false
                },
                timeAxisHeaderClick: (event) => {
                    processTimeAxisHeaderClick(props.scheduler, event);
                },
                eventSelectionChange: ({action, selected, selection}) => {
                    let currentSelectedEvent = selected[0];
                    if (selection.length > 1) {
                        if (action === "select") {
                            let firstSelectedEvent = selection[0];
                            if (currentSelectedEvent.data.resourceId !== firstSelectedEvent.data.resourceId) {
                                props.scheduler.current.schedulerInstance.deselectEvent(currentSelectedEvent)
                            }
                        }
                    }
                },
                eventDrop: (event) => {
                    const originalStart = event.context.origStart;
                    const originalStartDay = DateHelper.get(originalStart, "d");
                    event.eventRecords.forEach((eventRecord) => {
                        const newStartDay = DateHelper.get(eventRecord.startDate, "d")
                        if (originalStartDay !== newStartDay) {
                            DateHelper.copyTimeValues(eventRecord.startDate, originalStart)
                            DateHelper.copyTimeValues(eventRecord.endDate, originalStart)
                        }
                        if (!eventRecord.isCopy) {
                            setFeaturingType(eventRecord)
                            sendUpdateEvent(eventRecord, SELECTED_GAME.BA.toLowerCase());
                        }
                    })

                    props.scheduler.current.schedulerInstance.refreshRows();
                },
                beforeCopy: (event) => {

                    clearCopiedFieldForEvents(event.source.eventStore)
                    let disabledEvent = event.records.find(record => [EVENT_TYPE.ASO, EVENT_TYPE.OFFER, EVENT_TYPE.PROMO, EVENT_TYPE.PUSH].includes(record.type));
                    if (isExists(disabledEvent)) {
                        return false;
                    } else {
                        event.records.forEach(record => {
                            record.isCopied = true
                            record.originalID = record.id
                            record.copiedTime = DateHelper.get(record.startDate, 'h')
                        })
                        return true
                    }
                },
                beforePaste: (event) => {
                    if (event.resourceRecord.id === event.records[0].resourceId) {
                        return isExists(event.date)
                    } else {
                        return false
                    }
                },
                eventResizeEnd: ({eventRecord}) => {
                    DateHelper.copyTimeValues(eventRecord.endDate, eventRecord.startDate);
                    eventRecord.duration = DateHelper.diff(eventRecord.startDate, eventRecord.endDate, 'd');
                    setHoursForEventDates(eventRecord, timeInStartDate);
                    setFeaturingType(eventRecord);
                    sendUpdateEvent(eventRecord, SELECTED_GAME.BA.toLowerCase());
                    hideEventTimeRange(props.scheduler);
                },
                eventClick: ({event, eventRecord}) => {
                    setEventTimeRange(props.scheduler, eventRecord);
                    return eventClick(event, eventRecord)
                },
                scheduleClick: () => {
                    hideEventTimeRange(props.scheduler);
                }
            },

            scheduleMenuFeature: {
                processItems({resourceRecord, items}) {
                    if (isExists(items.addEvent)) {
                        items.addEvent.disabled = false
                    }

                    if (resourceRecord.type === EVENT_TYPE.ASO
                        || resourceRecord.type === EVENT_TYPE.PROMO
                        || resourceRecord.type === EVENT_TYPE.PUSH
                        // || resourceRecord.type === EVENT_TYPE.FEATURING
                    ) {
                        items.addEvent.hidden = true;
                        if (items.pasteEvent !== undefined) {
                            items.pasteEvent.hidden = true;
                        }

                    } else if (resourceRecord.type === EVENT_TYPE.RELEASE) {
                        if (items.pasteEvent !== undefined) {
                            items.pasteEvent.hidden = true;

                        }
                    }
                }
            },
            eventDragSelectFeature: true,
            eventDragCreateFeature: false
        }}

    const processBeforeEventDelete = ({eventRecords}) => {
        console.log("processBeforeEventDelete")
        console.log(eventRecords)
        const deletedEvents = eventRecords.map(
            (eventRecord) => isExists(eventRecord.parentRelease)
                ? props.eventStore.getById(eventRecord.parentRelease)
                : eventRecord
        )
        setDeleteRecords(deletedEvents);
        setExistsInAsana(!!eventRecords[0].data.link);
        props.showPopup("deleteEvents");
        return false;
    }

    const showEditor = useCallback((eventRecord, resourceRecord) => {
        eventRecord.type = resourceRecord.data.type;
        let popupData
        if (resourceRecord.data.type === EVENT_TYPE.EVENT) {
            popupData = {hasTime: true, type: EVENT_TYPE.EVENT, hasEndDate: true}
        } else if (resourceRecord.data.type === EVENT_TYPE.AB_TEST) {
            popupData = {hasTime: true, type: EVENT_TYPE.AB_TEST, hasEndDate: true}

        } else if ([EVENT_TYPE.HOLIDAY, EVENT_TYPE.FEATURING, EVENT_TYPE.IMPORTANT_DATES].includes(resourceRecord.data.type)) {
            popupData = {hasTime: false, type: "default", hasEndDate: true}
        } else if ([EVENT_TYPE.COMMUNITY].includes(resourceRecord.data.type)) {
            popupData = {hasTime: false, type: "default", hasEndDate: false}
        } else if ([EVENT_TYPE.ASO].includes(resourceRecord.data.type)) {
            popupData = {hasTime: false, type: resourceRecord.data.type, hasEndDate: true}
        } else if (eventRecord.hasGeneratedId && resourceRecord.data.type === EVENT_TYPE.RELEASE) {
            popupData = "releaseCreate"
        } else if (!eventRecord.hasGeneratedId && resourceRecord.data.type === EVENT_TYPE.RELEASE) {
            popupData = "releaseEdit"
        } else {
            popupData = {hasTime: true, type: "default",  hasEndDate: true}
        }
        debugger
        eventRecord.status = eventRecord.hasGeneratedId ? "create" : "update";
        props.setResourceRecord(resourceRecord);
        props.setEventRecord(eventRecord);
        props.showPopup(popupData);
    }, []);

    function getEventTemplates() {
        const templatesByGame = store.getState()?.calendarConfig?.templates;
        return templatesByGame?.find(config => (config.game === SELECTED_GAME.BA && config.type === EVENT_TYPE.EVENT))?.templateGidsByName
    }

    function getConfigs() {
        let overrideConfigs = {
            eventTooltipFeature: {

                template: data => {

                    function getEventStatus() {
                        let arrayStatusText = [];
                        data.eventRecord.isArchive && arrayStatusText.push(`archive event`);
                        return !isEmptyArray(arrayStatusText) ? `<div><b>Status:</b> ${arrayStatusText.join(", ")}</div>` : ""
                    }

                    function getTemplateInfo() {
                        const templatesByGame = store.getState()?.calendarConfig?.templates;
                        let templates = templatesByGame?.find(config => (config.game === SELECTED_GAME.BA && config.type === EVENT_TYPE.EVENT))?.templateGidsByName
                        if (data.eventRecord.type === EVENT_TYPE.EVENT) return getLiveOpsTemplateInfoNameIfExist(templates, data.eventRecord);
                        return !isExists(data.eventRecord?.template)?  "no template": "unknown template"
                    }

                    return `<div>
                ${getEventStatus()}
                ${(data.eventRecord.name !== undefined) ? `<div><b>Name</b>: ${(data.eventRecord.name) + ((data.eventRecord.parentRelease !== undefined && data.eventRecord.parentRelease !== null) ? "%" : "")}</div>` : ''}
                <div><b>Date</b>: ${DateHelper.format(data.eventRecord.startDate, "Y.MM.DD")} ${DateHelper.format(data.eventRecord.startDate, "HH:mm")} - ${DateHelper.format(data.eventRecord.endDate, "Y.MM.DD")} ${DateHelper.format(data.eventRecord.endDate, "HH:mm")}</div>
                <div><b>Template name</b>: ${(getTemplateInfo())}</div>
                ${(data.eventRecord.eventId !== undefined && data.eventRecord.eventId !== null && data.eventRecord.eventId.length !== 0) ? `<div><b>Event ID</b>: ${data.eventRecord.eventId}</div>` : ''}
                ${(data.eventRecord.levelsWW !== undefined && data.eventRecord.levelsWW !== null && data.eventRecord.levelsWW.length !== 0) ? `<div><b>Levels WW</b>: ${data.eventRecord.levelsWW}</div>` : ''}
                ${(data.eventRecord.levelsBR !== undefined && data.eventRecord.levelsBR !== null && data.eventRecord.levelsBR.length !== 0) ? `<div><b>Levels BR</b>: ${data.eventRecord.levelsBR}</div>` : ''}
                ${(data.eventRecord.levelsPL !== undefined && data.eventRecord.levelsPL !== null && data.eventRecord.levelsPL.length !== 0) ? `<div><b>Levels PL</b>: ${data.eventRecord.levelsPL}</div>` : ''}
                ${(data.eventRecord.persent !== undefined && data.eventRecord.persent !== null) ? `<div><b>Persent</b>: ${data.eventRecord.persent}%</div>` : ''}
                ${(data.eventRecord.countries !== undefined && data.eventRecord.countries !== null && data.eventRecord.countries.length !== 0) ? `<div><b>Countries</b>: ${data.eventRecord.countries}</div>` : ''}
                ${(data.eventRecord.note !== undefined && data.eventRecord.note !== null && data.eventRecord.note.length !== 0) ? `<div><b>Note</b>: ${data.eventRecord.note}</div>` : ''}
        </div>`
                }
            },
        }
        return props.getBryntumConfigs(getEditorConfigs, overrideConfigs);
    }
    return (
        <>{isExists(user.email) &&
        <>
            <BryntumScheduler
                ref={props.scheduler}
                {...getConfigs()}

            />
            {(props.popupShown && typeof props.popupShown === "object") &&
            <Popup
                labelsConfig={labelsConfig}
                eventRecord={props.eventRecord}
                eventStore={props.eventStore}
                resourceRecord={props.resourceRecord}
                onSave={props.handleClickSave}
                onClose={props.hideEditor}
                popupData={props.popupShown}
                game={SELECTED_GAME.BA}
                scheduler={props.scheduler.current.schedulerInstance}
            />
            }
            {props.popupShown === "noteEdit" && (
                <EditNotePopup
                    eventRecord={props.eventRecord}
                    eventStore={props.eventStore}
                    game ={SELECTED_GAME.BA}
                    onClose={props.hideEditor}
                />
            )}
            {props.popupShown === "releaseCreate" && (
                <ReleaseCreatePopup
                    closePopup={props.hideEditor}
                    eventRecord={props.eventRecord}
                    eventStore={props.eventStore}
                    dependencyStore={props.dependencyStore}
                    resourceRecord={props.resourceRecord}
                    onSave={props.handleClickSave}
                    onClose={props.hideEditor}
                    onSaveChild={props.handleClickSaveChildRelease}
                    releaseNames={RELEASES}
                    game={SELECTED_GAME.BA}
                />
            )}
            {props.popupShown === "releaseEdit" && (
                <ReleaseEditPopup
                    closePopup={props.hideEditor}
                    eventRecord={props.eventRecord}
                    eventStore={props.eventStore}
                    dependencyStore={props.dependencyStore}
                    resourceRecord={props.resourceRecord}
                    onSave={props.handleClickSave}
                    onClose={props.hideEditor}
                    onSaveChild={props.handleClickSaveChildRelease}
                    game={SELECTED_GAME.BA}
                />
            )}
            {props.popupShown === "deleteEvents" && (
                <DeletePopup
                    deleteRecords={deleteRecords}
                    eventStore={props.eventStore}
                    selectedGame={SELECTED_GAME.BA}
                    existsInAsana={existsInAsana}
                    onClose={props.hideEditor}
                    scheduler={props.scheduler}
                />
            )}
        </>
        }
        </>);
};
export default manageCalendar(BACalendar, SELECTED_GAME.BA)