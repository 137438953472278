import React, {useState} from "react";

import './TemplatePopup.scss';
import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {pushEventChangesInAsana, sendAddEvent, sendUpdateEvent} from "../requests";
import {useSelector} from "react-redux";
import {MONGO_STATUS, SELECTED_GAME} from "../constants";
import {getTemplatesByGame} from "../redux/calendar-config/actions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 180,
        },
    },
};

export default function TemplatePopup(props) {
    const [popupState, setPopupState] = useState({
        ...props.eventRecord.data
    });
    const templates = useSelector((state) => state.calendarConfig.templates)



    const handleClickSave = () => {
        sendAddEvent(popupState, props.game.toLowerCase());
        props.onClose();
    }


    function handleChange(event, templateName) {
        setPopupState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
            promoTemplateName: templateName
        }));
    }

    return (
        <div className='popup-mask'>
            <div className='popup-note'>
                <header>
                    Save Promo for {props.eventRecord.name}
                </header>
                {(props.game === SELECTED_GAME.SC) &&
                    <FormControl style={{width: '100%', marginBottom: 15, marginTop: 15}} variant="standard">
                        <InputLabel id="promo-template">Choose template</InputLabel>
                        <Select
                            labelid="promo-template"
                            id="event-name"
                            name="promoTemplate"
                            value={popupState.promoTemplate}
                            onChange={(event) => handleChange(event, getTemplatesByGame(templates, props.game)
                                .templateGidsByName.Promo[event.target.value])}
                            MenuProps={MenuProps}>
                            {Object.entries(getTemplatesByGame(templates, props.game)?.templateGidsByName?.Promo).map(([id, name]) => (
                                <MenuItem key={id} value={id}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>}
                <footer>
                    <Button variant="contained" color="primary" onClick={handleClickSave}>Save</Button>
                </footer>
            </div>
        </div>
    )
}
