import React, {useState} from "react";


export default function LoginForm() {

    const [btnDisabled, setBtnDisabled] = useState(false);

    function login() {
        setBtnDisabled(true);
        fetch("/auth",
            {
                method: 'GET',
                credentials:  'include'
            }).then(response => response.json()
            .then(result => {
                window.location = result.url;
            }).catch(()=> {
                setBtnDisabled(false)
            })

        );
    }

    return (
        <>
            <form className="login-content">
                <h1 className="login-content__header">Belka Calendar</h1>
                <button type="button" className="b-button b-tab login-content__btn" disabled={btnDisabled}
                        onClick={login}>Asana Login
                </button>
            </form>
        </>
    )
}