import React, {useEffect, useState} from 'react';

import {useSelector} from "react-redux";
import {Input, MenuItem, Select} from "@mui/material";
import {isExists} from "../utils";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 180,
        },
    },
};
export default function TimeDropdown({startDate, changeStartTime, style, defaultTime}) {

    const timeConfig = useSelector((state) => state.calendarConfig.timeConfig)
    const [startTime, setStartTime] = useState(undefined);

    function getHoursAndMinutes(seconds) {
        const hours = seconds / 3600;
        const minutes = (seconds % 3600) / 60;
        return {hours, minutes}
    }

    useEffect(() => {
        let hour = startDate.getHours();
        let min = startDate.getMinutes();
        let seconds = (hour * 3600) + min * 60;
        if (defaultTime !== undefined && defaultTime !== null) {
            let keys = Object.keys(defaultTime);
            seconds = defaultTime[keys[0]]
            setStartTime(seconds);
            changeStartTime(getHoursAndMinutes(seconds))
        } else if (Object.values(timeConfig).includes(seconds)) {
            setStartTime(seconds);
        }
    }, [])


    function startTimeHandler(event) {
        let seconds = Number.parseInt(event.target.value);
        const time = getHoursAndMinutes(seconds)

        changeStartTime(time)
        setStartTime(seconds)
    }

    return (
        <Select
            labelid="time"
            id="time"
            name="startTime"
            value={isExists(startTime)? startTime: 0}
            onChange={(event) => startTimeHandler(event)}
            input={<Input/>}
            style={style}
            MenuProps={MenuProps}>
            {Object.entries(timeConfig).map(([key, value]) =>
                <MenuItem key={key} value={value}>
                    {key}
                </MenuItem>)}
        </Select>

    )
}