import { ADD_SYNC_RESOURCES, REMOVE_SYNC_RESOURCES } from "./actions"

const initialState = {}

const syncResourcesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SYNC_RESOURCES:
            return {
                ...state,
                ...action.payload
            }
        case REMOVE_SYNC_RESOURCES:
            const _state = Object.assign({}, state)
            delete _state[action.payload]

            return {
                ..._state
            }
        default:
            return state
    }
}

export default syncResourcesReducer
