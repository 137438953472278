export const ADD_SYNC_RESOURCES = "ADD_SYNC_RESOURCES"
export const REMOVE_SYNC_RESOURCES = "REMOVE_SYNC_RESOURCES"

const addSyncResources = (data) => ({
    type: ADD_SYNC_RESOURCES,
    payload: data
})

const removeSyncResources = (requestId) => ({
    type: REMOVE_SYNC_RESOURCES,
    payload: requestId
})

export const addSyncResourcesAction = (syncResources) => async (dispatch) => {
    dispatch(addSyncResources(syncResources))
}

export const removeSyncResourcesAction = (requestId) => async (dispatch) => {
    dispatch(removeSyncResources(requestId))
}
