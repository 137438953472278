import {RESOURCES} from "../../constants";

export const getSubEventsMapByResourceId = (resourceName) => {
    const subEventMap = new Map();

    switch (resourceName) {
        case RESOURCES.MATCH3:
            subEventMap.set('. 25%', 0);
            subEventMap.set('. 100%', 14);
            subEventMap.set('. Забрать уровни в клиент', 28);
            subEventMap.set('. Отключить AB-тест', 42);
            break;
        default:
            break;
    }
    return subEventMap;
}

export const getCompositeSubEvents = (scheduler, eventRecord) => {
    return scheduler.eventStore.getEventsForResource(eventRecord.resourceId)
        .filter(subEvents => subEvents.parentCompositeEventId === eventRecord.id);
}

export const decodeResourceId = (resourceId) => {
    return decodeURIComponent(escape(window.atob(resourceId)));
}

export const isHidePushCompositeEventButton = (eventRecord) => {
    return eventRecord.isParentCompositeEvent === false && eventRecord.parentCompositeEventId;
}

export const getSubEventDate = (eventRecordDate) => {
    return new Date(Date.UTC(eventRecordDate.getFullYear(), eventRecordDate.getMonth(),
        eventRecordDate.getDate(), eventRecordDate.getHours(), eventRecordDate.getMinutes()));
}