import { Backdrop, CircularProgress } from "@mui/material"
import * as Sentry from "@sentry/react"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./App.css"
import { fetchUser } from "./redux/actions"
import { fetchCalendarConfig } from "./redux/calendar-config/actions"
import AuthenticatedRouting from "./routes/AuthenticatedRouting"
import UnauthenticatedRouting from "./routes/UnauthenticatedRouting"
import {fetchCalendarCategories} from "./redux/categories/actions";

const App = () => {
    const user = useSelector((state) => state.user.currentUser)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchUser())
        dispatch(fetchCalendarConfig())

        setInterval(() => {
            dispatch(fetchUser())
            dispatch(fetchCalendarConfig())
        }, 90000)
    }, [])

    return (
        <>
            {user === undefined
                ? (
                    <Backdrop open sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
                : user === null ? <UnauthenticatedRouting /> : <AuthenticatedRouting />}
        </>
    )
}

export default Sentry.withProfiler(App)
