import {FETCH_CALENDAR_CONFIG} from "../types";
import {EVENT_TYPE} from "../../constants";

export function fetchCalendarConfig() {
    return async  dispatch => {

        try {
            const response = await fetch("/calendar-configs", {method: 'GET'});
            const body = await response.text();
            let payload = null;
            if (body !== '') {
                payload = JSON.parse(body);

            }
            dispatch({type: FETCH_CALENDAR_CONFIG, payload})
        } catch (e) {
            dispatch({type: FETCH_CALENDAR_CONFIG, payload: {}})
        }
    }
}

export function getTemplatesByGame(templatesConfig, game, type = EVENT_TYPE.EVENT) {
    return templatesConfig.find(config => config.game === game && config.type === type)
}
export function getProjectTeamsByGame(projectTeamsConfig, game) {
    return projectTeamsConfig.filter(config => config.gameName === game)
}