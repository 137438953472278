import {isExists} from "../../utils";

export const eventClick = (event, eventRecord) => {

    if (event.shiftKey && isExists(eventRecord.link)) {

        setTimeout (() => { window.open(eventRecord.link,'_blank'); },20);

        event.preventDefault();

    }
}