import {DateHelper} from "@bryntum/scheduler/scheduler.umd"
import {Client} from "@stomp/stompjs"
import SockJS from "sockjs-client"
import store from "./store"
import {isExists, isLocal, propertyExists} from "./utils"
import {SYNC_INFO, SYNC_PROGRESS} from "./redux/sync-asana-status/actions";
import {COMPLETED_SYNC_STATUS, MONGO_STATUS} from "./constants";
import {setCustomEventStyles, updateSyncFromAsanaStatus} from "./calendars/bryntum_helpers";
import {getCompositeSubEvents} from "./calendars/bryntum_configs/compositeEventUtils";

const createClient = (heartbeat = 10000) => new Client({
    heartbeatIncoming: heartbeat,
    heartbeatOutgoing: heartbeat,
    debug: (message) => {
        console.debug(message)
    },
    webSocketFactory: () => new SockJS(`${isLocal() ? "http://localhost:8080" : ""}/sync`)
})

export const initWebSocket = (scheduler, game) => {

    const client = createClient()

    function addDependency(dependencyStore, events) {
        events.sort((first, second) => {
            let dateFirst = DateHelper.parse(first.startDate, 'YYYY-MM-DD');
            let dateSecond = DateHelper.parse(second.startDate, 'YYYY-MM-DD');
            return DateHelper.isBefore(dateFirst, dateSecond) ? -1 : 1;
        });
        events.forEach((record, index) => {
            let eventDependencies = dependencyStore.getEventDependencies(record);
            if (index > 0) {
                dependencyStore.remove(eventDependencies);
                dependencyStore.add({from: events[index - 1].id, to: record.id});
            }
        })
    }

    client.onConnect = (frame) => {
        client.subscribe(`/${game}/asana-sync-info`, (response) => {

            const body = JSON.parse(response.body)
            store.dispatch({type: SYNC_INFO, payload: body})
            updateSyncFromAsanaStatus(scheduler, body);
        })
        client.subscribe(`/${game}/asana-sync-progress`, (response) => {

            const data = JSON.parse(response.body)

            function updateMongoStatusForEvents() {
                data.filter(item => item.status.completedSyncStatus === COMPLETED_SYNC_STATUS.IN_PROGRESS
                    && isExists(item.task._id))
                    .map(item => item.task._id)
                    .forEach(id =>  {
                        const event = scheduler.eventStore.getById(id)
                        if (isExists(event) && event.mongoStatus!== MONGO_STATUS.NOT_MODIFIED) {
                            event.setAsync("mongoStatus", MONGO_STATUS.NOT_MODIFIED);
                            if (event.isParentCompositeEvent) {
                                let subEvents = getCompositeSubEvents(scheduler, event);
                                subEvents.forEach(subEvent => {
                                    if (isExists(subEvent) && subEvents.mongoStatus!== MONGO_STATUS.NOT_MODIFIED) {
                                        subEvent.setAsync("mongoStatus", MONGO_STATUS.NOT_MODIFIED);
                                    }
                                })
                            }
                        }
                    })
            }

            updateMongoStatusForEvents();

            store.dispatch({type: SYNC_PROGRESS, payload: data})
        })
        client.subscribe(`/${game}/add`, (response) => {

            const addedEvent = JSON.parse(response.body)
            const event = propertyExists(addedEvent, "tempId")
                ? scheduler.eventStore.getById(addedEvent.tempId)
                : null

            if (isExists(event)) {
                event.setAsync("id", addedEvent.id)
                event.setAsync("mongoStatus", addedEvent.mongoStatus)

                // showToast(addedEvent.id, `Added ${addedEvent.name}`, "b-green")
            } else {
                if (propertyExists(addedEvent, "colorParams")) {
                    setCustomEventStyles(addedEvent, addedEvent.colorParams)
                }

                scheduler.eventStore.addAsync(addedEvent)
            }

            // store.dispatch(addSyncEventAction(addedEvent.id))
        })
        client.subscribe(`/${game}/update`, (response) => {

            const updatedEvent = JSON.parse(response.body)
            const event = scheduler.eventStore.getById(updatedEvent.id)
            if (isExists(event)) {
                event.setAsync(updatedEvent)
                if (!propertyExists(updatedEvent, "parentRelease")) {
                    // store.dispatch(addSyncEventAction(updatedEvent.id))
                } else {
                    // hideToast(updatedEvent.id)
                }
            }
        })
        client.subscribe(`/${game}/remove`, (response) => {
            const removedEventId = response.body

            const event = scheduler.eventStore.getById(removedEventId)

            function removeNestedRelease() {
                if (event.nestedIdEvents !== undefined) {
                    event.nestedIdEvents.forEach(idEvent => {
                        scheduler.eventStore.remove(idEvent)
                        // store.dispatch(addSyncEventAction(removedEventId))
                    })
                }
            }

            if (isExists(event)) {
                scheduler.eventStore.remove(removedEventId)
                removeNestedRelease();
            }
        })
        client.subscribe(`/${game}/update-note`, (response) => {
            const body = JSON.parse(response.body)
            const event = scheduler.eventStore.getById(body.id)
            if (isExists(event)) {
                event.setAsync("note", body.note)
            }
        })
        client.subscribe(`/${game}/asana-add`, (response) => {
            const addedEvent = JSON.parse(response.body)
            const event = scheduler.eventStore.getById(addedEvent.id)
            const promoEvent = scheduler.eventStore.getById(addedEvent.id + "_promo")
            if (isExists(promoEvent)) {
                promoEvent.data.link = addedEvent.promoLink ? addedEvent.promoLink : addedEvent.link
                scheduler.eventStore.addAsync(promoEvent)
            }

            if (isExists(event)) {
                event.setAsync(addedEvent)
            } else {
                if (propertyExists(addedEvent, "colorParams")) {
                    setCustomEventStyles(addedEvent, addedEvent.colorParams)
                }

                scheduler.eventStore.addAsync(addedEvent)
            }
        })
        client.subscribe(`/${game}/asana-update`, (response) => {
            const updatedEvent = JSON.parse(response.body)

            const event = scheduler.eventStore.getById(updatedEvent.id)
            const promoEvent = scheduler.eventStore.getById(updatedEvent.id + "_promo")
            if (isExists(promoEvent)) {
                promoEvent.data.link = updatedEvent.promoLink ? updatedEvent.promoLink : updatedEvent.link
                scheduler.eventStore.addAsync(promoEvent)
            }
            if (isExists(event)) {
                event.setAsync(updatedEvent)
            }
        })
        client.subscribe(`/${game}/asana-remove`, (response) => {
            console.log("2) /cm/asana-remove")
            const removedEventId = response.body

            const event = scheduler.eventStore.getById(removedEventId)

            if (isExists(event)) {
                scheduler.eventStore.remove(removedEventId)
            }
        })
        client.subscribe(`/${game}/asana-sync`, (response) => {
            const data = JSON.parse(response.body)

            data.updated.forEach((updatedEvent) => {
                const event = scheduler.eventStore.getById(updatedEvent.id)

                if (event) {
                    event.setAsync(updatedEvent)
                } else {
                    if (propertyExists(updatedEvent, "colorParams")) {
                        setCustomEventStyles(updatedEvent, updatedEvent.colorParams)
                    }

                    scheduler.eventStore.addAsync(updatedEvent)
                }
            })

            data.removed.forEach((removedEvent) => {
                scheduler.eventStore.remove(removedEvent.id)
            })
        })
        client.subscribe(`/${game}/update-release`, (response) => {
            function updateRelease(event, release) {

                if (isExists(event)) {
                    //update for current user
                    event.setAsync("id", release.id)
                } else if (!scheduler.eventStore.includes(release.id)) {
                    //add new
                    scheduler.eventStore.addAsync(release)
                    if (propertyExists(release, "colorParams")) {
                        setCustomEventStyles(release, release.colorParams)
                    }
                } else {
                    scheduler.eventStore.getById(release.id).setAsync("nestedIdEvents", release.nestedIdEvents)
                }
            }

            const releases = JSON.parse(response.body)
            releases.forEach(release => {

                const event = propertyExists(release, "tempId")
                    ? scheduler.eventStore.getById(release.tempId)
                    : null
                updateRelease(event, release);
                addDependency(scheduler.dependencyStore, releases);
            })
            scheduler.refreshRows();
        })
        client.subscribe(`/${game}/remove-nested-release`, (response) => {
            const updateParentRelease = (parentRelease) => {
                const actualParentRelease = scheduler.eventStore.getById(parentRelease.id)
                if (isExists(actualParentRelease)) {
                    actualParentRelease.set("nestedIdEvents", parentRelease.nestedIdEvents)
                }
            }

            const releases = JSON.parse(response.body)

            if (Array.isArray(releases)) {
                const nestedRelease = releases.find((release) => isExists(release.parentRelease))
                if (isExists(nestedRelease)) {
                    const parentRelease = releases.find((release) => !isExists(release.parentRelease))
                    if (isExists(parentRelease)) {
                        const removedEvents = scheduler.eventStore.remove(nestedRelease.id)
                        if (removedEvents.length === 1) {
                            updateParentRelease(parentRelease)
                        }
                    } else {
                        scheduler.eventStore.remove(nestedRelease.id)
                    }
                }

            }
        })
        client.subscribe(`/${game}/create-parent-release`, (response) => {
            function createParentRelease() {
                let parentRelease = releases.find((element) => element.parentRelease === undefined);
                const event = propertyExists(parentRelease, "tempId")
                    ? scheduler.eventStore.getById(parentRelease.tempId)
                    : null
                if (isExists(event)) {
                    event.setAsync("id", parentRelease.id)
                    event.nestedIdEvents.forEach(nestedId => {
                            if (scheduler.eventStore.includes(nestedId)) {
                                scheduler.eventStore.remove(nestedId)
                            }
                        }
                    )
                    event.setAsync("nestedIdEvents", parentRelease.nestedIdEvents)
                    event.setAsync("name", parentRelease.name)
                    event.setAsync("mongoStatus", parentRelease.mongoStatus)
                } else {
                    scheduler.eventStore.addAsync(parentRelease)
                    if (propertyExists(parentRelease, "colorParams")) {
                        setCustomEventStyles(parentRelease, parentRelease.colorParams)
                    }
                }
                ;


                return parentRelease;
            }

            const releases = JSON.parse(response.body)

            let parentRelease = createParentRelease();
            releases.forEach(release => {
                if (release.id !== parentRelease.id) {
                    scheduler.eventStore.addAsync(release)
                    if (propertyExists(release, "colorParams")) {
                        setCustomEventStyles(release, release.colorParams)
                    }
                }
            })
            addDependency(scheduler.dependencyStore, releases);
            scheduler.refreshRows();
        })
        client.subscribe(`/${game}/asana-add-parent-release`, (response) => {
            const releases = JSON.parse(response.body)
            let parentRelease = releases.find((element) => element.parentRelease === undefined);

            function updatedParent(parentRelease) {
                const event = scheduler.eventStore.getById(parentRelease.id)
                if (isExists(event)) {
                    event.setAsync(parentRelease)
                }
            }

            updatedParent(parentRelease);
            scheduler.refreshRows();
        })
    }

    client.activate()
    return client;
}
