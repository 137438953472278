import React, {Fragment, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, Link, List, ListItem, Table, TableCell, TableHead, TableRow,
    TextField
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';


export default function SyncTemplatePopup({popupSyncTemplate, setPopupSyncTemplate}) {

    const [taskId, setTaskId] = useState("");
    const [response, setResponse] = useState("");
    const [disableActions, setDisableActions] = useState(false);
    const [error, setError] = useState(false);

    const refresh = () => {
        setError(false);
        setResponse("");
    }

    function syncByTaskId() {
        // setDisableActions(true);
        fetch(`ba/sync-template`,
            {
                method: 'POST',
                body: taskId
            }
        )
            .then(async (response) => {
                if (!response.ok) {
                    setError(true);
                   return response.text()
                }
                setError(false);
                return response.text();
            }).then((response) => {
                // setDisableActions(false);
                setResponse(response);
        })
    }

    const responseTable = (response) => {
        return (
            <Fragment>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Task for synchronization
                            </TableCell>
                            <TableCell>
                                Action
                            </TableCell>
                            <TableCell>
                                Affected task
                            </TableCell>
                        </TableRow>
                        {response.sort((a, b) => a.taskForSync.name.localeCompare(b.taskForSync.name)).map(r => (
                            <TableRow key={r.task.gid}>
                                <TableCell>
                                    {r.taskForSync.name}
                                </TableCell>
                                <TableCell>
                                    {r.action}
                                </TableCell>
                                <TableCell>
                                    <Link href={r.task.asanaLink}>{r.task.name}</Link>
                                </TableCell>
                            </TableRow>
                        ))
                        }
                    </TableHead>
                </Table>
            </Fragment>
        )
    }


    return (
        <Dialog fullWidth
                maxWidth={'md'}
                open={popupSyncTemplate}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="sync_popup__blue_gradient"
                         sx={{textAlign: "center", color: "white"}}>Введите ID задачи</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{textAlign: "center", pt: 3}}>
                    <TextField fullWidth
                               label={"Id задачи для синхронизации"}
                               onChange={(e) => setTaskId(e.target.value)}
                    />
                    {/*{disableActions && <CircularProgress/>}*/}
                    {error && response}
                    {!error && response}
                </DialogContentText>

            </DialogContent>
            <DialogActions sx={{justifyContent: "end"}}>
                <Button
                    disabled={disableActions}
                    onClick={() => {
                        refresh();
                        setPopupSyncTemplate(false);
                    }}
                    className="sync_popup__red_gradient"
                    sx={{color: "white"}}>Отмена</Button>
                <Button
                    disabled={disableActions || taskId === ""}
                    onClick={() => {
                        syncByTaskId()
                        refresh();
                    }}
                    className="sync_popup__blue_gradient"
                    sx={{color: "white"}}>Синхронизировать</Button>

            </DialogActions>
        </Dialog>
    )
}