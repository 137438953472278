import {FETCH_USER, USER_GAME_CONFIGS, UPDATE_USER_GAME_CONFIGS, LOG_OUT} from "./types";


const initialState = {
    currentUser: undefined
};
export const userReducer =(state = initialState, action) => {

    switch (action.type) {
        case FETCH_USER:
            return {currentUser: action.payload};
        case USER_GAME_CONFIGS:
            return state.currentUser.gameConfigs.find(item => item.selectedGame === action.payload.game);
        case UPDATE_USER_GAME_CONFIGS:
            putUserConfigsAction(action.payload, state.currentUser.email).then(console.info)
            return {currentUser: action.payload};
        case LOG_OUT:
            return {...state, currentUser: null}
        default: return state
    }
};
export function getConfigByGame(user, game) {
    return user.gameConfigs.find(item => item.selectedGame === game);
}

export const putUserConfigsAction = async (userConfig, email) => {
    const response = await fetch(
        `/user-configs`,
        {
            method: "PUT",
            headers: {
                "Authorization": email,
                "content-type": "application/json"
            },
            body: JSON.stringify(userConfig)
        }
    )

    if (response.status !== 200) {
        console.error("Can't update user configs")
    }
}


