import {FETCH_LABELS_CONFIG} from "../types";


const initialStateTimeConfig = {timeConfig : {}, templates: []}
export const labelReducer = (state = initialStateTimeConfig, action) => {
    switch (action.type) {
        case FETCH_LABELS_CONFIG:
            return  action.payload;
        default:
            return state
    }
};